#stripeBillingProposal {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 10%;
  z-index: 999;
  margin: auto;
  background: #00000075;
  max-height: 100vh;
  overflow: auto;
}

#stripeBillingProposal form#cardForm {
  background: #fff;
  overflow: scroll;
  margin-bottom: 10%;
  position: inherit;
  bottom: 20px;
  /* max-height: 600px; */
  margin-top: -100px;
  max-width: 500px;
  margin: auto;
  border: 1px solid lightgrey;
  border-radius: 2px;
}

#stripeBillingProposal form#cardForm .pop {
  box-shadow: none;
  margin-bottom: -40px !important;
}

#createOrgStepBackground {
  position: fixed;
  overflow: scroll;
  top: 100px;
  left: 0px;
  height: 100%;
  width: 100%;
  padding: 5%;
  margin: auto;
  display: block;
  background: #0000005c;
  z-index: 1000;
}

#createOrgStep {
  background: #fff;
  border-radius: 2px;
  padding: 50px;
  margin: auto;
  border-radius: 1px;
  border: 1px solid #c4c8d8;
  box-shadow: 0 2px 5px 0px #e3e5ec73;
  text-align: center;
}

#newProjectButtonArrows {
  line-height: 50px;
  margin-top: -2px;
}

.proposalStatus {
  font-weight: 500;
  /* font-size: 16px; */
}

#marketplaceProfile {
  /* margin: 0px 15px; */
  background: #fff;
  /* padding: 5%; */
  width: 100%;
  /* border: 1px solid #c4c8d8; */
  /* box-shadow: 0 2px 5px 0 #e3e5ec; */
  /* max-width: 1000px; */
  /* margin: auto; */
}

input.proposalInput,
textarea.proposalInput {
  width: 100%;
  border: 2px ridge;
}

#marketplaceProfileName p {
  font-weight: 700;
  font-size: 24px;
  font-family: open sans;
  color: #312e52;
}

#marketplaceProfileDesc p {
  font-family: "open sans";
  font-weight: 400;
  line-height: 24px;
  max-width: 650px;
}

#wtfTag {
  display: table-caption;
  background-color: #ecf4ff;
  border: 1px solid #0565AA;
  border-radius: 5px !important;
  color: #2B2C2D;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 5px;
  padding: 5px;
  max-width: fit-content;
}

#wtfTag p {
  font-size: 14px;
}

p.roleTag {
  font-weight: 600;
}

.SP_Checkboxes .MuiTypography-root {
  min-width: 18px;
  height: 18px;
  font-size: 13px;
  font-weight: 400;
  border-radius: 9999px;
  padding: 0px 10px;
  font-size: 1em !important;
  margin-top: -10px !important;
}

.marketplaceServiceProvider {
  padding: 5%;
  background: rgb(255, 255, 255);
  margin-bottom: 25px;
  margin-bottom: 1em;
  width: 100%;
  padding: 2rem !important;
  background: #fdfdfd;
  margin-top: 1rem;
  margin-left: 1rem;
  padding: 1rem;
  width: 100%;
  border: 1px solid #c4c8d8;
  box-shadow: 0 2px 5px 0 #e3e5ec;
  max-width: 750px;
}

#service_provider_onboarding_page .MuiFormControl-root {
  height: 40px !important;
  background: #fff;
  /* padding: 5px; */
}

#service-provider-onboarding-textarea .MuiFormControl-root {
  height: auto !important;
}

#service-provider-onboarding-textarea .MuiInputBase-multiline {
  padding: 0;
}

#service_provider_onboarding_page input#service-provider-standard-basic,
#service_provider_onboarding_page input#organization-standard-basic {
  height: 25px !important;
  padding-left: 5px;
}

#service_provider_onboarding_page .react-autosuggest__input {
  width: 500px;
}

/* Regular Tablets */

@media only screen and (max-width: 1000px) {
  #mobileSearchFilters {
    font-size: 24px !important;
  }

  .advancedFiltersArrow {
    font-size: 20px !important;
  }

  #numRecords {
    padding-left: 15px;
  }
}

/* Mobile */

@media only screen and (max-width: 480px) {
  #searchHeader {
    font-size: 26px !important;
  }

  #mobileSearchFilters {
    font-size: 18px !important;
  }

  .advancedFiltersArrow {
    font-size: 14px !important;
  }

  #filterBox h1#searchHeader {
    font-size: 20px !important;
  }

  #numRecords {
    font-size: 13px !important;
  }
  .marketPlaceContainer {
    display: block;
  }
}


/* fyc */
.grantConsultantHeader {
  font-size: 18px;
  font-weight: bold;
  padding: 1%;
  background-color: #FAFAFB;
  margin: 0;
}
.marketplaceProfileImg {
  float: right !important;
  border-radius: 50% !important;
  overflow: hidden !important;
  display: flex;
  object-fit: cover;
  justify-content: flex-end;
}
.marketPlaceContainer {
  margin: 0% 5% !important;
}
.marketplaceProfileName {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: black;
  margin: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 10px;
}
.marketPlaceBtn {
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
  height: 75px !important;
  
}
.MuiButton-label {
  text-transform: none !important;
}
.marketPlaceAboutHeader {
  padding: 0;
  text-align: end;
  margin-right: 10px;
  color: #2B2C2D;
  font-size: 15px;
}
.marketPlaceAboutDescription {
  color: #2B2C2D;
  font-size: 15px;
  padding: 0;
}
.viewOrgProfileBtn {
  float: right;
  background: none;
  border: none;
  color: #0565AA;
  font-size: 15px;
}
.marketPlaceFlex {
  display: flex;
}
.MessageMeBtn {
  display: flex;
  justify-content: end;
}
.marginLeftAuto{
  margin-left: auto;
}
.wtfTagClass {
  display: initial;
  margin-right: 10px;
}
.serviceProviders{
  margin-left: 14%;
}

@media only screen and (max-width: 999px) {
  .marketPlaceAboutHeader {
    font-weight: bold;
    text-align: start;
    margin-bottom: 10px;
  }
  .serviceProviders{
    margin-left: 4%;
  }
}

@media only screen and (max-width: 480px) {
  .marginLeftAuto{
    margin-left: 0;
  }
  .serviceProviders{
    margin-left: 2%;
  }
  .marketPlaceFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .MessageMeBtn {
    display: flex;
    justify-content: center;
  }
  .marketplaceProfileImg {
    justify-content: flex-start;
    float: left !important;
    margin-bottom: 10px;
  }
  /* .MessageMeBtn {
    float: none;
    display: block;
  } */
  
  .wtfTagClass {
    display: block;
  }
  .removeBr {
    display: none;
  }
  .viewOrgProfileBtn {
    float: left;
    margin-top: 10px;
  }
}