.pagination>li>a, .pagination>li>span {
    border: none;
    color: #666;
    margin: 0;
}

#searchListingsPagination {
    text-align: right;
    background: #fff;
    padding: 5%;
    border-bottom: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    z-index: 3;
    color: #2aa79f!important;
    background: none!important;
    background-color: #fff!important;
    cursor: pointer;
    border-bottom: 2px solid #2aa79f!important;
    border-radius: 0!important;
}

.pagination>li>a:hover {
    z-index: 3;
    color: #2aa79f!important;
    background: none!important;
    background-color: #fff!important;
    cursor: pointer;
    border-bottom: 2px solid #2aa79f!important;
    border-radius: 0!important;
}