input#letsGoButton {
    background: #fff;
    border: none;
    display: block;
    text-align: center;
    margin: auto;
    height: 50px;
    width: 150px;
    border-radius: 3px;
    margin-top: 50px;
    font-weight: 600;
    font-size: 18px;
}

input#hireWriterButton {
    background: teal;
    color: #fff;
    border: none;
    display: block;
    text-align: center;
    margin: auto;
    height: 50px;
    width: auto;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 3px;
    margin-top: 100px;
    font-weight: 600;
    font-size: 18px;
}

h3#connectProfessional {
    color: #fff;
    font-family: raleway;
    text-align: center;
    max-width: 600px;
    font-size: 22px;
    margin: auto;
}

h1#dashFundingAmount {
    font-size: 7em!important;
    text-align: center!important;
    color: #2ca79e!important;
    font-family: poppins!important;
    font-weight: 600;
    margin-bottom: 10%!important;
    margin-top: 10%;
}

h1#availableFundingHeader {
    font-size: 4.5em!important;
    font-weight: 600;
    font-family: poppins!important;
    color: #444!important;
    text-align: center!important;
    margin-bottom: 5%;
}

img#grantHeroDashIMG {
    max-height: 400px;
    height: auto;
    margin: auto;
    text-align: center;
    display: block;
    max-width: 100%;
}

h1#availableFundingSubHeader {
    font-size: 1.75em!important;
    font-family: poppins!important;
    color: #333!important;
    text-align: center;
    max-width: 600px;
    margin: auto;
    margin-top: 10%;
    letter-spacing: -0.025em;
    font-size: 3em!important;
    font-weight: 600;
}

h2#fundingIdentified {
    text-align: center;
    font-family: ubuntu;
    font-weight: 600;
    color: #444;
    font-size: 2.5em;
    margin-top: 10%;
}

#additionalFundingRangeDiv {
    max-width: 750px;
    margin: auto;
    padding: 5%;
}

hr#additionalFundingAwardHR {
    width: 100px;
    margin-top: 25px;
    margin-bottom: 25px;
    border-top: 2px solid lightgrey;
}

input#goToProducts {
    height: 50px;
    border-radius: 2px;
    border: none;
    background: #444;
    color: #fff;
    font-family: ubuntu;
    font-weight: 300;
    font-size: 20px;
    padding-left: 35px;
    padding-right: 35px;
    margin-top: 2%!important;
    text-align: center;
    margin: auto;
    display: block;
}

hr#dashHeaderHR {
    max-width: 100px;
    border-top: 2px solid lightgrey;
    margin-top: 50px;
    margin-bottom: 25px;
}

hr#dashMidHR {
    max-width: 100px;
    border-top: 2px solid lightgrey;
    margin-top: 50px;
    margin-bottom: 50px;
}

div.RSPBprogression {
    background: linear-gradient(to right, rgb(0,128,128), rgb(41,167,158))!important;
    width: 80%!important;
    margin-left: 10%;
}

.RSPBprogressBar {
    height: 15px;
    line-height: 1;
    border-radius: 10px;
    position: relative;
    background-color: rgba(123, 167, 165, 0.8);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
    max-width: 500px;
    margin: auto;
    margin-top: 50px!important;
    margin-bottom: 100px!important;
}

p#rangeSliderAmount {
    margin-bottom: -35px;
    margin-top: 15px;
    font-family: ubuntu;
    color: teal;
    margin-left: -30%;
    font-size: 20px;
}

h2#additionalFundingRange {
    font-family: raleway;
    font-size: 20px;
    text-align: center;
    margin-bottom: 7%;
}

h2#getStartedH2 {
    color: #fff;
    font-family: open sans;
    font-weight: 900;
    text-align: center;
    margin-top: 50px;
    font-size: 34px;
}

#getStartedDiv {
    height: auto;
    background: teal;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 0px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 75px;
}

#noNewRecommendations {
    padding: 5%;
    background: #fff;
    text-align: center;
    box-shadow: 0 1px 5px -1px rgba(47,54,57,.07), 0 0 5px 0 rgba(47,54,57,.06), 0 1px 10px 0 rgba(47,54,57,.05);
}

h3.noNewRecommendationsText {
    color: #555;
    font-family: raleway;
}

button.noNewRecommendationsButton {
    padding: 10px;
    border-radius: 3px;
    background: #456270;
    color: #fff;
    box-shadow: 0 1px 6px rgba(57,73,76,0.35);
    font-family: open sans;
    font-weight: 600;
    margin: 10px;
    width: 30%;
}

#noNewRecommendations h4 {
    color: #666;
    font-family: ubuntu;
    font-size: 32px;
}

#recommendedOpportunitiesDashboard h1 {
    font-size: 36px;
    font-family: ubuntu;
    color: #666;
    padding-top: 5%;
}

#recommendedOpportunitiesDashboard {
    background: white;
    position: absolute;
    top: 0;
    left: 300px;
    width: calc(100% - 300px)!important;
    padding: 5%;
    min-height: 100vh;
}

#fundingFoundRow {
    margin-top: 10%;
    margin-bottom: 5%;
}

#diyResourcesConsulting {
    width: 100%;
    padding: 5%;
    padding-bottom: 15%;
    background: teal;
    margin-top: 150px;
}

#diyResourcesConsulting h1#stillNeedHelpH1,
#diyResourcesConsulting p#didYouKnow
{
    color: #fff!important;
}

#diyResourcesConsulting #maximizeAwardDiv input {
    background: #1e4353!important;
}

#DIYaddOns #maximizeAwardDiv {
    border: 1px solid lightgrey;
    height: 400px;
    border-radius: 2px;
    border: 1.5px solid #c4c8d8;
    box-shadow: 0 2px 5px 0 #e3e5ec;
    padding-left: 5%;
    padding-right: 5%;
}

#DIYaddOns #maximizeAwardDiv input {
    margin: initial;
    margin-top: 5%;
    text-align: left;
}

#DIYaddOns h1#stillNeedHelpH1 {
    text-align: left;
    font-size: 32px;
}

#DIYaddOns p#didYouKnow {
    text-align: left;
}

p#dashboardDynamicText {
    font-size: 18px;
}

p#dashboardDynamicText strong {
    font-family: montserrat;
    font-size: 20px;
}

#diyPage {    
    background: white;
    position: absolute;
    left: 300px;
    top: 0;
    padding-top: 10%;
}

@media only screen and (max-width: 999px) {
    #recommendedOpportunitiesDashboard {
        background: white;
        position: absolute;
        top: 170px;
        left: 0;
        width: 100%!important;
        padding: 5%;
        min-height: 100vh;
    }

    #diyPage {
        background: white;
        position: absolute;
        left: 0;
        top: 175px;
        padding-top: 10%;
    }

    img#grantHeroIMG {
        max-height: 400px;
    }
    
    #diyResourcesConsulting {
        padding-bottom: 30%;
    }

    #recommendedColumn.col-md-6 {
        padding: 0!important;
    }

    .recommendedListing {
        margin-bottom: 0px;
        padding-bottom: 25px;
        border-bottom: 3px solid lightgrey;
    }

    i#discardRecommendedfromFavorites.material-icons, i#addRecommendedtoFavorites.material-icons {
        display: none;
    }

    #recommendedBody {
        padding-top: 10%;
    }
}

@media only screen and (max-width: 768px) {
    h1#availableFundingHeader {
        margin: 25px;
        font-size: 3.5em!important;
    }

    img#grantHeroDashIMG {
        height: 275px;
    }

    h2#fundingIdentified {
        font-size: 2em;
        margin-top: 20%;
    }

    input#goToProducts {
        font-size: 16px!important;
        margin-bottom: 50px;
    }

    h1#dashFundingAmount {
        font-size: 5.5em!important;
    }

    #diyResourcesConsulting {
        width: 130%;
        margin-left: -15%;
        margin-right: -15%;
        padding: 15%;
        padding-bottom: 50%;
        background: teal;
        margin-top: 150px;
        margin-bottom: -160px;
    }

}