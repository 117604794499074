.thumbsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
}
  
.previewThumb {
    display: inline-flex;
    margin-bottom: 8px;
    margin-right: 8px;
    width: auto;
    height: 125px;
    padding: 4px;
    box-sizing: border-box;
}
  
.previewThumbInner {
    display: flex;
    min-width: 0;
    overflow: hidden;
}
  
img#previewThumbImg {
    display: block;
    width: auto;
    height: 100%;
}

#imgUploadContainer {
    padding: 0;
}