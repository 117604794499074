#savedListings .ais-Hits-item {
    width: 100%!important;
}






#savedListingsSearchBar {
    padding: 5%;
    background: #fff;
    border-bottom: 1px solid lightgrey;
}

i#clearSavedBarIcon {
    margin-right: 60px;
    margin-bottom: -25px;
    line-height: 0px;
    color: #999;
  }

  #savedListingsRow #listingsSearchForm {
      width: 75%!important;
  }

  button#clearSavedBar {
    background: none;
    margin-left: -50px;
    border: none;
    width: 50px;
    height: 0;
    padding: 0;
}

i#deleteFavoriteButton.material-icons {
    font-size: 30px;
    color: #999;
    cursor: pointer;
    width: 1em;
    margin-top: 5px;
}

#savedListingsRow {
    max-width: 1000px;
    margin: auto;
    -webkit-box-shadow: 0 1px 6px rgba(57,73,76,0.35);
    box-shadow: 0 1px 6px rgba(57,73,76,0.35);
    padding: 0;
}

#noSavedListings {
    text-align: center;
    background: #fff;
    padding: 10%;
    box-shadow: 0 1px 5px -1px rgba(47,54,57,.07), 0 0 5px 0 rgba(47,54,57,.06), 0 1px 10px 0 rgba(47,54,57,.05);
}

h3.noSavedFavoritesText {
    color: #555;
    font-family: ubuntu;
}

p.noSavedFavoritesText {
    color: #333;
    font-size: 16px;
    font-family: open sans;
}

button#noFavoritesSearchButton {
    padding: 10px;
    border-radius: 3px;
    background: #456270;
    color: #fff;
    -webkit-box-shadow: 0 1px 6px rgba(57,73,76,0.35);
    box-shadow: 0 1px 6px rgba(57,73,76,0.35);
    font-family: raleway;
}

@media only screen and (max-width: 999px) {
    i#deleteFavoriteButton.material-icons {
        margin-right: 15px!important;
        margin-top: 15px!important;
    }

    #savedListingsRow #listingsSearchForm {
        width: 100%!important;
    }
}