.modal-dialog {
    width: 75%;
    margin: 0;
    top: -1px;
    right: -1px;
    bottom: 0;
    position: absolute;
    border: none;
}

.modal-body {
    position: relative;
    padding: 0;
    background: #F2F2F2;
}

.modal-backdrop {
    background-color: #00000069;
}

.modal-content {
    border-radius: 2px;
}

#listingPreviewModal.modal-dialog {
    width: 100%!important;
    margin: 0;
    top: -1px;
    right: -1px;
    bottom: 0;
    position: absolute;
    border: none;
}

#listingPreviewModal .modal-body {
    position: relative;
    padding: 5%;
    background: #F2F2F2;
}

#listingPreview .modal-backdrop {
    background-color: #00000069;
}

#listingPreviewModal .modal-content {
    border-radius: 2px;
    width: 90%;
    max-width: 700px;
    max-height: 85vh;
    overflow-y: scroll;
    margin: auto;
    top: 10vh;
    display: block;
    z-index: 0;
}

#listingPreviewModal .modal-footer {
    position: relative;
    right: 0;
    bottom: 0;
    width: 100%;
}

#listingPreviewModal button.btn {
    min-width: 250px;
    height: 40px;
    color: #fff;
}

button#listingModalPreviewLogin {
    background: #29a79f;
}

button#listingModalPreviewRegister {
    float: left;
}

button#listingModalPreviewLogin:hover {
    background: teal;
}

#listingPreviewModal p {
    text-align: center;
    font-size: 16px;
}

h1#modalTitle {
    font-family: ubuntu;
    font-weight: 700;
    color: #29a79f;
    font-size: 36px;
    text-align: center;
}

#listingPreviewModal h2 {
    color: #555;
    font-family: open sans;
    font-weight: 600;
    text-align: center;
    font-size: 24px;
}

hr#alreadySignedUpHR {
    margin: 10px;
    margin-bottom: 5px;
    border-top: 1px solid #888;
    width: 10%;
    margin-left: auto;
    margin-right: auto;
}

/* .modal-content {
    border-radius: 2px;
    top: 0;
    bottom: 0;
    position: absolute;
    background: #f2f2f2;
} */

@media only screen and (max-width: 999px) {
    .modal-dialog {
        width: 100%;
        margin: 0;
        top: -1px;
        right: -1px;
        bottom: 0;
        position: absolute;
        border: none;
    }

    #listingMain {
        top: 0px;
    }
}

@media only screen and (max-width: 700px) {
    #listingPreviewModal button.btn {
        min-width: 250px;
        height: 50px;
        color: #fff;
        margin: auto;
        display: block;
        margin-bottom: 5%;
        margin-top: 3%;
    }
    button#listingModalPreviewRegister {
        margin-left: -10px;
    }

    button#listingModalPreviewLogin {
        margin-right: -10px;
    }

    button#listingModalPreviewRegister,
    button#listingModalPreviewLogin {
        font-size: 10px!important;
        min-width: 100px!important;
    }
}

@media only screen and (max-width: 350px) {
    #listingPreviewModal button.btn {
        min-width: 250px;
        height: 50px;
        color: #fff;
        margin: auto;
        display: block;
        margin-bottom: 5%;
        margin-top: 3%;
    }

    button#listingModalPreviewRegister,
    button#listingModalPreviewLogin {
        font-size: 10px!important;
        min-width: 200px!important;
        margin: auto;
        text-align: center;
        float: none;
        display: block;
        margin-bottom: 10px;
    }
}