#portalProfile h2 {
    color: #666;
    font-weight: 400;
    font-size: 18px;
}

.react-autosuggest__suggestions-list {
    list-style: none;
    padding: 0;
    border: 1px solid lightgrey;
    max-height: 100vh;
    overflow-y: scroll;
    margin-top: -10px;
}

.react-autosuggest__suggestions-list li {
    background: #fefefe;
    width: 100%;
    padding: 10px;
    font-family: raleway;
    border: 1px solid lightgrey;
    cursor: pointer;
}

.react-autosuggest__suggestions-list li:hover {
    background: #eee;
}

#settingsForm #managePasswordForm input {
    margin-bottom: 0px!important;
}

#settingsForm {
    -webkit-box-shadow: 0px 16px 23px -4px rgba(0,0,0,0.51);
    -moz-box-shadow: 0px 16px 23px -4px rgba(0,0,0,0.51);
    box-shadow: 0px 16px 23px -4px rgba(0,0,0,0.51);
    flex: 1;
}
/* 
.react-autosuggest__suggestions-list ul li {
    border: 1px solid grey;
} */

#userSettings .alert-success {
    padding: 15px;
    border-radius: 5px;
    text-align: center;
}

.settingsSelectOption {
    padding-bottom: 20px;
}

.settingsSelectOption h2, #advancedSettingsTextAreas h2 {
    margin-bottom: 0;
}

.ReactPasswordStrength {
    border: none!important;
    height: 50px;
}

#userSettings {
    min-height: 98vh; 
    display: flex;
}

#userSettings body {
    font-family: Raleway;
    background: #eee;
}

.updateMessage {
    color: #29A79F;
    font-size: 20px
}

h2.settings {
    display: block;
    font-size: 15px;
    font-weight: 100;
    margin-top: 5px;
    margin-bottom: 5px;
}

#dragDropArea p {
    margin: 0;
}

.settings .row {
    margin-left: 0px;
    margin-right: 0px;
}

.thumbsContainer {
    max-width: 100%;
}

#settingsTabs {
    margin: 70px 0 0 40px;
    width: 200px;
}

.settings .tab {
    display: block;
    font: 16px;
    text-align: left;
    padding: 15px 10px;
    margin: 5px 0;
    cursor: pointer;
}

.unselected {
    background-color: #29A79F;
    color: white;
}

.selected {
    background-color: white;
    color: #43425D;
}

.checkMarks {
    display: inline-block;
    border: 1px solid #43425D;
    color: #43425D;
    margin: 10px 0px;
    margin-right: 20px;
    padding: 10px;
    vertical-align: middle;
}

.checkMarksSelected {
    display: inline-block;
    border: 1px solid #43425D;
    color: #43425D;
    margin: 10px 0px;
    padding: 1px 4px;
    margin-right: 20px;
}

#settingsForm {
    background: white;
    vertical-align: top;
    margin-top: 20px;
    margin-bottom: 10px;
    flex: 1;
    padding: 10px 40px 30px 40px;
}

.settings input, .settings textarea {
    border: 1px #43425D solid;
    border-radius: 4px;
    width: 100%;
    padding: 5px;
    height: 40px;
}

.settings input {
    height: 30px;
}

#main-settings .col-md-6 > div {
    padding: 10px 40px 10px 0px;
}

button.saveForm {
    border: 0px;
    height: 50px;
    width: 150px;
    border-radius: 4px;
    background: #43425D!important;
    color: white;
    margin: 10px 0px;
}

.teamMemberTable {
    display: block;
    height: 255px;
    overflow-y: scroll;
}

.teamMemberTable div {
    padding: 0px;
    text-align: left;
}

.emptyButton {
    height: auto;
    width: auto;
    border: 0px;
    background: white;
    color: #43425D;
}

.roundedButton {
    border-radius: 30px;
    padding: 10px 20px;
    width: 150px;
    height: auto;
    margin: 10px 20px;
}

.roundedDiv {
    border-radius: 30px;
    padding: 10px 15px;
    width: 150px;
    height: auto;
    border: 0px;
    background: #43425D;
    color: white;
    margin: 10px 20px;
    padding: 10px 20px;
}

.radioSelection {
    width: 15px;
    height: 15px;
}

#userSettings textarea {
    height: 255px;
}

.settingsCheckbox {
    max-width: 1000px;
    padding: 5px 20px;
    font-size: 12px;
}

.settingsCheckbox input {
    height: 15px;
    width: 15px;
}

.settingsCheckbox label {
    width: 250px;
    vertical-align: top;
}

.teamMemberTable tr {
    height: 50px;
    display: block;
}

.teamMemberTable td {
    width: 50%;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

#settingsForm tbody {
    display: block;
}

.error {
    color: red;
    margin-top: 5px;
}

#settingsForm {
    -webkit-box-shadow: 0px 16px 23px -4px rgba(0,0,0,0.51);
    -moz-box-shadow: 0px 16px 23px -4px rgba(0,0,0,0.51);
    box-shadow: 0px 16px 23px -4px rgba(0,0,0,0.51);
}

/* body {
    font-family: Raleway;
    background: #eee;
} */

.updateMessage {
    color: #29A79F;
    font-size: 20px
}

#userSettings h2 {
    display: block;
    font-size: 15px;
    font-weight: 300;
    margin-top: 5px;
    margin-bottom: 5px;
}

#dragDropArea p {
    margin: 0;
}

#settingsTabs {
    margin: 70px 0 0 40px;
    width: 180px;
}

.tab {
    display: block;
    font: 16px;
    text-align: left;
    padding: 15px 10px;
    margin: 5px 0;
    cursor: pointer;
}

.unselected {
    background-color: #29A79F;
    color: white;
}

.selected {
    background-color: white;
    color: #43425D;
}

.checkMarks {
    display: inline-block;
    border: 1px solid #43425D;
    color: #43425D;
    margin: 10px 0px;
    margin-right: 20px;
    padding: 10px;
    vertical-align: middle;
}

.checkMarksSelected {
    display: inline-block;
    border: 1px solid #43425D;
    color: #43425D;
    margin: 10px 0px;
    padding: 1px 4px;
    margin-right: 20px;
}

#settingsForm {
    background: white;
    vertical-align: top;
    margin-top: 20px;
    margin-bottom: 10px;
    flex: 1;
    padding: 10px 40px 30px 40px;
}

#settingsForm input, #settingsForm textarea {
    border: 1px #bbbbc5 solid;
    border-radius: 4px;
    width: 100%;
    padding: 5px;
    margin-bottom: 15px!important;
}

textarea#profileBio, textarea#organizationTextArea {
    width: 100%;
    min-height: 100px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 14px;
    color: #333;
    font-weight: 500;
    padding-left: 5px;
}

#advancedSettingsTextAreas input {
    height: 38px!important;
    width: 100%;
    border-radius: 5px;
    border: 1px solid lightgrey!important;
}

#settingsForm input {
    height: 30px;
}

#profileForm input, #organizationForm input, #managePasswordForm input {
    height: 38px;
    border: 1px solid #bbb;
    font-weight: 500;
    border-radius: 3px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    padding-left: 5px;
}

#profileForm h2, #organizationForm h2 {
    margin-bottom: 0;
}

/* #mainSettings h1 {
    color: teal;
    font-family: ubuntu;
    margin-bottom: 5%;
} */

.half > div {
    padding: 10px 40px 10px 0px;
}

.teamMemberTable {
    display: block;
    height: 255px;
    overflow-y: scroll;
}

.teamMemberTable div {
    padding: 0px;
    text-align: left;
}

.emptyButton {
    height: auto;
    width: auto;
    border: 0px;
    background: white;
    color: #43425D;
}

.roundedButton {
    border-radius: 30px;
    padding: 10px 20px;
    width: 150px;
    height: auto;
    margin: 10px 20px;
}

.roundedDiv {
    border-radius: 30px;
    padding: 10px 15px;
    width: 150px;
    height: auto;
    border: 0px;
    background: #43425D;
    color: white;
    margin: 10px 20px;
    padding: 10px 20px;
}

.radioSelection {
    width: 15px;
    height: 15px;
}

#userSettings textarea {
    height: 255px;
}

#userSettings .checkbox {
    max-width: 1000px;
    padding: 5px 20px;
    font-size: 12px;
}

#userSettings .checkbox input {
    height: 15px;
    width: 15px;
}

#userSettings .checkbox label {
    width: 250px;
    vertical-align: top;
}

.teamMemberTable tr {
    height: 50px;
    display: block;
}

.teamMemberTable td {
    width: 50%;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

tbody {
    display: block;
}

.error {
    color: red;
    margin-top: 5px;
}

input#password_input {
    margin-bottom: 5%;
}

input#password_confirm_input {
    margin-bottom: 5%;
}

.profileSelectOption {
    font-size: 12px!important;
}

@media only screen and (max-width: 999px) {
    #settingsTabs {
        margin: 0;
        width: 180px;
        position: absolute;
        top: 76px;
        left: 0;
        width: 100%;
        line-height: 30px;
    }

    #settingsForm button {
        width: 100%;
    }

    #userSettings .unselected {
        background-color: #29A79F;
        color: white;
        margin: 0;
        border-bottom: 2px solid #f0f0f0;
    }

    #userSettings .main-body {
        margin-top: 180px;
        padding-left: 0;
        padding-right: 0;
    }

    #settingsForm {
        padding-top: 20%;
        padding-bottom: 20%;
        padding-left: 5%;
        padding-right: 5%;
        margin: 0;
        margin-top: 325px;
    }

    #userSettings form {
        padding: 0 !important;
    }

    #userSettings h1 {
        margin-left: 0 !important;
    }

    #userSettings .col-md-6 {
        padding-left: 0;
    }

    #userSettings footer {
        margin-top: 0;
    }

    .half {
        width: 100%;
    }

    .half > div {
        padding: 0;
        padding-bottom: 5%;
    }

    #dragDropArea {
        display: inline-block;
        cursor: pointer;
        top: 25%;
        transform: none;
    }

    #dragDropArea p {
        height: 200px !important;
        width: 200px !important;
    }

    input#changePhotoButton {
        top: 100% !important;
        left: 0 !important;
        width: 60% !important;
        text-align: center;
        transform: translate(0%, 50%) !important;
    }

    #changePhotoButton {
        transform: translate(0%, 50%) !important;
    }

    #userSettings.row {
        padding-bottom: 0 !important;
    }
}
