.stripe-product {
    /* padding-right: 40px; */
    /* padding-left: 40px; */
    padding-bottom: 40px;
    
    /* height: 100vh; */
    position: relative;
}

h2#serviceDetails {
    margin-bottom: 5%;
    font-size: 28px;
    font-weight: 600;
    color: #23283a;
}

h1#checkoutHireAGW {
    font-family: Montserrat;
    font-weight: 600;
    color: #333;
    font-size: 36px;
    margin-left: 10px;
    margin-top: 0px;
    margin-bottom: -50px;
}

.grey-background {
    background: #eee;
}

.stripe-product.overflow .adjustable-width {
    width: 100%!important;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    /* overflow: auto; */
}

.adjustable-width-sm {
    width: 60%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 500px) {
    .adjustable-width {
        width: 95%;
    }

    .adjustable-width-sm {
        width: 100%;
    }
}

.opengrants-logo {
    font-family: Ubuntu;
    color: #29A79F;
    margin: auto;
    line-height: 90px;
    font-size: 38px;
}

.background-white {
    background: white;
}

.pop {
    padding: 30px 15px 20px 15px;
    border-radius: 3px;
    overflow: auto;
    box-shadow: 0 0 10px -6px;
}

.background-white input {
    border: 1px solid #d3d3d3!important;
    float: left;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #eee;
}

.pop {
    padding: 30px 15px 20px 15px;
    border-radius: 3px;
    overflow: auto;
    box-shadow: 0 0 10px -6px;
}

.overflow {
    overflow: auto;
    scroll-behavior: smooth;
}

/* #orderSummary {
    margin-top: 20px;
    margin-right: 40px;
} */

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.bold {
    font-weight: 700;
}

.stripe-product .radio-elements label {
    /* display: inline-block; */
    font-weight: normal;
    /* margin-left: 10px; */
}

.stripe-product .checkbox-elements label {
    display: inline;
    font-weight: normal;
    /* margin-left: 10px; */
}

.stripe-product input[type=checkbox],[type=radio] {
    margin-right: 10px !important;
}

.stripe-product input[type=checkbox] {
    width: auto;
    margin-top: 5px!important;
}

.center {
    text-align: center;
}

.center-element {
    margin: auto;
}

.gap-top {
    margin-top: 30px;
}

.gap-bottom {
    margin-bottom: 30px!important;
}

.gap-top-lg {
    margin-top: 80px;
}

.gap-bottom-sm {
    margin-bottom: 10px!important;
}

.gap-all {
    margin: 30px;
}

.gap-all-sm {
    margin: 15px;
}

.font-sm {
    font-size: 17px;
}

h3.font-sm {
    font-size: 17px;
    margin-bottom: -25px;
    font-weight: 600;
    font-family: Montserrat;
}

.font-md {
    font-size: 21px;
}

.font-lg {
    font-size: 32px;
}

.inline {
    display: inline-block;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-top {
    vertical-align: top;
}

.justify {
    text-align: justify;
}

.og-color {
    color: #29A79F
}

.success-color {
    color: #199719;
}

.error-color {
    color: rgb(207, 28, 28);
}

.blue-color {
    color: #1D4354;
}

.white-color {
    color: white;
}

.button-empty {
    color: #555!important;
    background: none!important;
    font-size: 25px;
    font-weight: 400;
    /* border: 1.5px solid lightgrey; */
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 9px;
    /* border-radius: 3px; */
    float: right;
}

.indent-left {
    margin-left: 20px !important;
}

.indent-right {
    margin-right: 20px;
}

.height-full {
    height: 100vh;
}

.height-sm {
    height: 100px;
}

.button-padded {
    padding: 15px 50px;
    border: 0;
    border-radius: 8px;
}

.button-padded-sm {
    padding: 10px;
    border: 0;
    border-radius: 8px;
}

.background-og-color {
    background-color: #29A79F;
    color: white;
}

.border {
    border-radius: 5px;
    padding: 5px;
}

.border-og {
    border: 1px solid #29A79F;
}

.border-grey {
    border: 1px solid #aaa;
    border-radius: 5px;
    padding: 5px;
}

/* .background-transparent {
    background: #aaaaaaaa;
    z-index: 10;
    position: absolute ;
    height: 100%;
    width: 100%;
    padding: 50px;
} */

.background-transparent {
    background: #aaaaaaaa;
    z-index: 10;
    position: fixed;
    height: 100%;
    width: 100%;
    padding: 50px;
    left: 0;
    top: 0;
}

.margin-move-left-40 {
    margin-left: 0px!important;
}

.line-md-theme {
    border-top: 3px solid #2e827c91;
}

button#placeOrder {
    background: #29a79f;
    /* min-width: 200px; */
    height: 50px;
    margin: 0;
}

button#applyCoupon {
    background: #29a79f;
    padding-left: 13px;
    padding-right: 13px;
    margin-left: 5px;
    height: 43px;
    margin-top: 0;
}

button#placeOrder:hover,
button#applyCoupon:hover {
    background: teal;
}

.line-sm {
    border-top: 1px solid #bbb;
}

.empty-style {
    padding: 0px !important;
    margin:  0px !important;
}

.auto-size {
    width: auto !important;
    height: auto !important;
}

.width-full {
    width: 100%;
}

.width-full input {
    width: 100%;
}

.link-list {
    position: absolute;
    right: 10%;
    top: 25px;
}

.link-list a {
    display: block;
    margin-bottom: 2px;
}

.circle {
    border-radius: 50%;
    padding: 5px 15px !important;
}

.division {
    padding: 50px 20px;
}

.font-poppins {
    font-family: ubuntu;
}

.bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
}

.relative {
    position: relative !important;
}

.padding-md {
    padding: 20px;
}

.padding-lg {
    padding: 50px !important;
}

.heading-bolded {
    font-weight: 600;
    color: #444;
    font-size: 2.5em;
}

.bold-none {
    font-weight: 300;
}

.line-height-sm {
    line-height: 1.25em;
}

.product-box {
    width: 300px;
    height: 600px;
    border: 1px solid #aaa;
    border-radius: 5px;
}

.equal-height {
    display: inline-grid;
    grid-template-columns: repeat(3, 1fr);
    margin-left: 15px;
    margin-right: 15px;
}

a.underline-white:visited {
    color: white;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #eee !important;
}

.purchaseHistory a:hover {
    /* background-color: #eee; */
    text-decoration: underline;
}