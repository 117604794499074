#hoursTrackingForm .MuiInputBase-input,
#hoursTrackingForm input#segment_Input_Date.MuiInputBase-input {
    height: 30px;
    margin-left: 5px;
}

#hoursTrackingForm {
    margin-top: 10px;

}

#newProjectProposal h3, .newProjectForm h3 {
    font-size: 18px;
    /* font-weight: 600; */
}

#newProjectProposal .projectProposalField {
    margin-bottom: 5%;
}

input#segment_Input_Date.MuiInputBase-input {
    height: 50px;
    margin-left: 5px;
}

.MuiInputBase-inputMultiline {
    line-height: 1.25em!important;
    padding: 5px;
}

.MuiTab-wrapper {
    /* color: #fff!important; */
    font-weight: normal;
}

h1#payoutMethodsHeader {
    font-family: ubuntu;
    background: #ececec;
    padding: 5%;
    border: 1px solid lightgrey;
    border-radius: 2px;
}

#noPayoutMethodsOnFile,
#viewPayoutMethod {
    width: 100%; 
    padding: 5%;
     background: #fff;
    border: 1px solid lightgrey;
    border-radius: 2px;
    margin-top: -35px;
}

#newPaymentMethod .MuiSelect-select.MuiSelect-select {
    padding-right: 24px;
    min-height: 25px;
    border: 1px solid lightgrey!important;
    min-width: 470px;
    margin-bottom: 15px;
    line-height: 25px;
    padding-left: 5px;
}

.editPayoutMethod svg.MuiSvgIcon-root.MuiSelect-icon {
    margin-top: -5px;
    margin-right: 10px;
    font-size: 24px;
}

table#segmentTable tr:nth-child(even) {
    background-color: #2a7ea787;
}

table#segmentTable tr td {
    padding: 1%;
    min-height: 50px;
    max-width: 250px;
    overflow: hidden;
}

#segmentTable label {
    padding-left: 5px;
    margin-top: -5px;
}

table#segmentTable th {
    padding: 1%;
    padding-bottom: 0;
    min-height: 50px;
}

#newProject .MuiButton-contained.Mui-disabled {
    color: rgb(218 218 218 / 85%)!important;
}

.WithStyles\(ForwardRef\(StepConnector\)\)-active-8 .WithStyles\(ForwardRef\(StepConnector\)\)-line-10,
.WithStyles\(ForwardRef\(StepConnector\)\)-completed-9 .WithStyles\(ForwardRef\(StepConnector\)\)-line-10 {
    background-image: linear-gradient(95deg,rgb(37 140 179) 0%,rgb(52 97 97) 50%,rgb(27 38 68) 100%)!important;
}

.MuiAppBar-colorPrimary {
    color: #fff!important;
    background-color: #2a7ea7!important;
    /* box-shadow: none!important; */
}

.PrivateTabIndicator-colorSecondary-21 {
    background-color: #2bcbe0;
}

.MuiButton-containedPrimary {
    color: #fff;
    background-color: #337ab7!important;
}

#newProject .MuiInputLabel-formControl {
    padding-left: 5px;
}

#newProject .MuiFormControl-root {
    margin-top: 0!important;
}

#newProject .MuiInputBase-input {
    padding-left: 5px;
}

#newProject .makeStyles-button-2 {
    height: 50px;
    width: 100px;
    font-size: 16px;}

.newProjectFormContainer {
    width: 100%;
    max-width: 750px;
    margin: auto;
    min-height: 300px;
    background: #fff;
    border: 1px solid lightgrey;
    border-radius: 2px;
    box-shadow: 0 1px 7px 2px rgb(0 0 0 / 14%);
}

.newProjectFormHeader {
    border-bottom: 1px solid lightgrey;
    background: #f1f2f4;
}

.newProjectForm {
    padding: 5%;
}

.newProjectFormHeader h1 {
    /* font-weight: 500; */
    color: #555;
    font-size: 32px;
    font-family: 'Ubuntu';
    margin: 0;
    padding: 2.5%;
}

.newProjectForm .css-yk16xz-control,
.newProjectForm .css-1pahdxg-control {
    height: 75px!important;
}

.newProjectNavButtons {
    max-width: 750px;
    margin: auto;
    margin-top: 2.5%;
    margin-bottom: 10%;
}

.PrivateTabIndicator-colorSecondary-4 {
    background-color: green !important;
}

button#newProjectButton {
    float: right;
    /* margin: 15px; */
    background: none;
    box-shadow: none;
    padding: 0px;
    border-radius: 25px;
    border: 1px solid lightgrey;
    height: 50px;
    width: 50px;
    color: #2a7ea7;
    font-size: 32px;
    border-style: groove;
}

button#viewProposalButton {
    float: right;
    background: none;
    box-shadow: none;
    border: 1px solid lightgrey;
    border-radius: 25px;
    padding: 0;
    width: 40px;
    height: 40px;
    border-style: groove;
    margin-top: 10px;
    color: #2a7ea7;
    font-size: 24px;
    /* font-weight: 700; */
}

#newProjectDiv .MuiStepper-alternativeLabel {
    align-items: flex-start;
    background: none;
    margin-top: -5%;
    /* border: 1px solid lightgrey;
    border-radius: 2px; */
}

/* #newProjectDiv .makeStyles-active-5,
#newProjectDiv .makeStyles-completed-6  {
    box-shadow: 0 4px 10px 0 rgb(0 0 0 / 25%);
    background-image: linear-gradient(136deg, rgb(33 154 170) 0%, rgb(33 154 169) 50%, rgb(30 67 83) 100%);
} */

#proposalDiv {
    background: #3333333b;
    padding-top: 5%!important;
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100%;
    height: auto;
    width: 100%;
    padding: 10%;
    overflow-y: scroll;
    margin: auto;
}

#proposalBackground {
    margin-bottom: 10%!important;
    background: #f9f9f9;
    border: 1px solid darkgrey;
    border-radius: 3px;
    padding: 5%;
    overflow-y: scroll;
    margin: auto;
    position: absolute;
    width: 100%;
    margin: auto;
    max-width: 80%;
    margin-top: 50px;
    margin-bottom: 50px;
    border: 1px solid #c4c8d8;
    box-shadow: 0 2px 5px 0px #e3e5ec;
}

#proposalBackground h3 {
    /* font-weight: 500; */
    margin-bottom: 0px;
    font-size: 23px;
    font-family: open sans;
    color: #555;
}

i#proposalCompletionDate {
    float: left!important;
    font-size: 32px!important;
    margin-left: 0px!important;
    margin-right: 15px!important;
}

#proposalBackground i {
    float: none;
    text-align: center;
    margin: 0;
    margin-left: -20px;
    font-size: 44px;
    color: #2a7ea7;
}

#proposalStatsRow hr {
    width: 10%;
    text-align: center;
    margin: auto;
    margin-top: 10px;
}

p.proposalStat {
    font-family: ubuntu;
    font-size: 24px;
    color: #555;
}

.client_list {
    margin-top: 20px;
}

.client_list h2 {
    margin-top: 0;
}

.client_list img {
    vertical-align: top;
    margin-right: 20px;
    margin-top: 10px;
    width: 100px;
}

.client_list div {
    display: inline-block;
    margin-right: 20px;
}

.client_list label {
    color: red !important;
    margin: 0;
}

.client_list_interactions p {
    display: inline-block;
    margin-right: 20px;
}

.client_list_interactions a {
    margin-right: 20px;
}

.service_provider_status {
    float: right;
}

.service_provider_status label  {
    display: inline-block;
    margin-right: 15px;
}

.client_manage img {
    margin-right: 20px;
    vertical-align: top;
    width: 200px
}

.client_heading {
    display: inline-block;
}

.client_heading a {
    margin-right: 20px;
}

.table_list th, .table_list td {
    padding: 5px 15px;
}

.side_by_side p {
    display: inline-block;
    margin-right: 20px;
}

.back_button {
    margin-bottom: 20px;
}

@media only screen and (min-width: 1000px) and (max-width: 1200px) {
    .message_input {
        margin-right: 10px;
        margin-bottom: 10px;
        width: 82%;
        padding: 5px;
    }
    .message_input_focus {
        margin-right: 10px;
        margin-bottom: 10px;
        width: 82%;
        padding: 5px;
    }
}

@media only screen and (min-width: 850px) and (max-width: 1000px)  {
    .message_input {
        margin-right: 10px;
        margin-bottom: 10px;
        width: 82%;
        padding: 5px;
    }
    .message_input_focus {
        margin-right: 10px;
        margin-bottom: 10px;
        width: 82%;
        padding: 5px;
    }
}

@media only screen and (min-width: 750px) and (max-width: 850px)  {
    .message_input {
        margin-right: 10px;
        margin-bottom: 10px;
        width: 82%;
    }
    .message_input_focus {
        margin-right: 10px;
        margin-bottom: 10px;
        width: 82%;
    }
}

@media only screen and (max-width: 750px) {
    .message_input {
        margin-right: 10px;
        margin-bottom: 10px;
        width: 100%;
        padding: 5px;
    }
    .message_input_focus {
        margin-right: 10px;
        margin-bottom: 10px;
        width: 100%;
        padding: 5px;
    }
}

.messages_box {
    /* border: 1px solid black; */
    padding: 15px;
}

.messages_box div {
    overflow: auto;
}

.input_error {
    color: red;
    font-size: 14px;
}

form > div {
    margin-top: 15px;
}

input {
    padding: 5px 10px;
}

textarea {
    padding: 5px 10px;
}

.dropdownButton {    
    background-color: #4161bb;
    padding: 10px;
    color: white;
    border-radius: 5px;
    margin-right: 15px;
}

.dropdownButton:hover {
    background-color: #5171cb;
}

.dropdownButton_open {
    background-color: #4161bb;
    border: 1px #bbb solid;
    padding: 9px;
}

#chatboxDropdown {
    position: absolute;
    top: 40px;
    width: 300px;
    right: 0;
    padding: 2.5%;
    background-color: #4161bb;
    border: 1px #bbb solid;
    margin-right: 15px;
    border-radius: 5px;
    box-shadow: 0px 7px 10px -8px;
}

#chatboxDropdown > a {
    display: block;
    padding: 2.5%;
    color: white;
    border-radius: 5px;
}

#chatboxDropdown > a:hover {
    background-color: #5171cb;
}

.MuiFormControl-root {
    background: none;
    border: 0!important;
}

#hoursTrackingForm .MuiInputBase-input {
    background: white;
    border: 1px solid #777;
    margin: 0;
    border-radius: 3px;
    padding: 10px;
    /* height: unset!important; */
}