/* body{
    background: white;
} */

#confirmEmail {
    max-width: 650px;
    background: #fff;
    padding: 5%;
    color: #555;
    text-align: center;
    margin: auto;
    margin-top: 5%;
    font-family: 'Raleway';
}

#confirmEmail .opengrantsLogo {
    margin: 0px;
    font-family: Ubuntu;
    color: #29A79F;
    font-size: 36px;
}

#confirmEmail .styledLine {
    border: 1px solid #e6e5e5;
    margin-bottom: 25px;
    margin-top: 40px;
}

#confirmEmail .heading {
    font-size: 28px; 
    font-weight: bold; 
    font-family: "open sans";
}

#confirmEmail .subheading {
    font-size: 16px;
    padding-bottom: 10px;
    font-weight: 100;
    margin-bottom: 0;
}

#confirmEmail .themeColor {
    color:#29A79F;
    cursor: pointer;
}

#confirmEmail .paragraph {
    font-size: 16px;
    padding-bottom: 10px;
    font-weight: 100;
    margin-bottom: 0;
    line-height: 0;
}

#confirmEmail input {
    display: inline-block;
    padding: 5px 15px;
    width: 100%;
    border-radius: 5px !important;
    border: 1px solid #29A79F !important;
    font-size: inherit;
}

#confirmEmail button {
    cursor: pointer;
    background: #29A79F;
    border-radius: 5px;
    color: white;
    padding: 5px 15%;
    border: 1px solid #29A79F;
    margin-top: 10px;
}

#letsChat {
    margin-top: 40px;
}

#confirmEmail .filler {
    height: 10vh;
}

#confirmEmail .bottom-links {
    position: absolute;
    bottom: 5%;
    left: 0;
    font-size: 14px;
    text-align: left;
    padding-left: 5%;
}

#confirmEmail .links {
    margin-bottom: 10px;
}

#confirmEmail .links > a {
    margin-right: 15px;
    color: #2AA79F;
    cursor: pointer;
    font-family: open sans;
    font-weight: 600;
}

@media only screen and (max-width: 999px) {
    #confirmEmail {
        padding: 10%;
    }

    #confirmEmail .bottom-links {
        position: inherit;
        white-space: pre-line;
        line-height: 3;
        margin-top: 100px;
        padding-left: 0;
    }

    #confirmEmail .links > a {
        display: block;
    }
}