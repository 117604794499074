#navContainer {
    height: 0px;
    margin-bottom: 25px;
}

#navbarHeaderDiv {
    display: none;
}

.Collapsible {
    padding-left: 15%!important;
    padding-right: 10%!important;
    /* padding: 4%; */
    display: block;
    width: 300px!important;
    color: #d6d6e7;
    font-family: raleway;
    font-size: 16px;
    margin: 0!important;
    line-height: 25px;
    cursor: pointer;
}

.Collapsible__trigger {
    display: block;
    font-weight: 400;
    text-decoration: none;
    color: #333333;
    position: relative;
    /* border: 1px solid white; */
    padding: 10px;
    padding-left: 15%;
    /* background: #00ac9d; */
    color: #d6d6e7; 
}
.Collapsible__trigger:after {
    font-family: 'FontAwesome';
    content: '\f107';
    position: absolute;
    left: 7px;
    top: 10px;
    display: block;
    transition: transform 300ms; 
}
.Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg); 
}
.Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; 
}
  
  .CustomTriggerCSS {
    background-color: lightcoral;
    transition: background-color 200ms ease; }
  
  .CustomTriggerCSS--open {
    background-color: darkslateblue; }
  

#portal {
    color:  #fff;
    font-family: ubuntu;
    font-weight: 700;
    text-align:  center;
    font-size: 30px;
    display: inline;
    vertical-align: middle;
    padding-left: 5px;
}

#portalOpenGrants {
    color:  #fff;
    font-family: ubuntu;
    text-align:  center;
    font-size: 20px;
}

h1#portalPoweredBy {
    font-family: ubuntu;
    color: #fff;
    font-size: 16px;
    text-align: center;
    font-style: italic;
    margin: 0;
}

h1#portalPoweredByMobile {
    font-family: ubuntu;
    color: #fff;
    font-size: 20px;
    text-align: left;
    font-style: italic;
    margin: 0;
    display: inline;
    margin-left: 35px;
    line-height: 70px;
}

#desktop-title {
    text-align: center;
    margin-top: 25px;
    margin-left: -15px;
    padding-bottom: 25px;
    border-bottom: 1px solid #3a456d;
}
/* WHITELABEL */
#desktop-title-epri {
    text-align: center;
    margin-top: 25px;
    margin-left: -15px;
    padding-bottom: 25px;
    /* border-bottom: 1px solid #3a456d; */
}

#desktopNavLinks {
    margin: auto;
    margin-top: 10%;
    display: block;
    /* height: 100%; */
    height: calc(100% - 310px);

    /* overflow-y: auto; */
}

#desktopNavLinks .material-icons {
    font-size: 20px;
    color: #d6d6e7;
    float: none;
    width: 16px;
    line-height: 16px;
    margin: 0;
    margin-right: 15px;
}

a#navLinkDashboard:hover,
a#navLinkSearch:hover,
a#navLinkTalent:hover,
a#navLinkMatches:hover,
a#navLinkFavorites:hover,
a#navLinkApply:hover,
a#navLinkResources:hover,
a#navLinkSettings:hover {
    background: #1215218c;
    cursor: pointer;
}

a#navLinkDashboard,
a#navLinkSearch,
a#navLinkTalent,
a#navLinkMatches,
a#navLinkFavorites,
a#navLinkApply,
a#navLinkResources,
a#navLinkSettings {
    padding-left: 15%!important;
    padding-right: 10%!important;
    padding: 4%;
    display: block;
    width: 300px!important;
    color: #d6d6e7;
    font-family: raleway;
    font-size: 15px;
    margin: 0!important;
    line-height: 25px;
}

a#navLinkDashboard.active,
a#navLinkSearch.active,
a#navLinkTalent.active,
a#navLinkMatches.active,
a#navLinkFavorites.active,
a#navLinkApply.active,
a#navLinkResources.active,
a#navLinkSettings.active {
    background: #1215218c;
    cursor: pointer;
    border-left: 3px solid #29a79e;
    text-decoration: none;
}

#desktop-resources {
    position: absolute;
    bottom: 0px;
    width: 300px;
    border-top: 1px solid #3a466d;
    padding-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    height: 48px;
}

#desktop-resources p {
    color: #fff;
    font-family: raleway;
    text-align: center;
    font-size: 12px;
}

#desktop-resources p:hover {
    cursor: pointer;
    text-decoration: underline;
}

#desktop-resources svg {
    display: inline-flex;
    margin-top: -7px;
    margin-right: 2px;
    min-width: 24px;
}

#desktop-resources .col-sm-4 {
    display: inline-flex;
}

#desktopNav-Container { 
    height: calc(100% - 310px);
    display: flex;
    overflow-y: scroll;
}
#desktopNav-Container::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 140px;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(rgba(255,255,255,0), rgb(35 40 58) 90%);    width: 100%;
    height: 4em;
    /* height: calc(100% - 310px); */
}

/* WHITELABEL */
#desktopNav-Container-epri { 
    height: 80%;
    display: flex;
    overflow-y: scroll;
    background-color: #212121 !important;
}
#desktopNav-Container-epri::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 140px;
    left: 0;
    pointer-events: none;
    /* background-image: linear-gradient(rgba(255,255,255,0), rgb(35 40 58) 90%);    width: 100%; */
    /* height: 4em; */
    /* height: calc(100% - 310px); */
}

#desktop-user {
    display: inline-block;
    border-top: 1px solid #3a476d;
    /* background: #23283a; */
    position: absolute;
    bottom: 40px;
    height: 100px;
    width: 300px;
    padding: 10%;
}

#desktop-user p {
    color: #fff;
    display: inline-block;
    margin-left: 55px;
}

#desktop-user #userInfo {
    display: inline-block;
}

.profile-circle {
    position: absolute;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    background: #29a79f;
    font-size: 18px;
    border: 1px solid #cacedcfa;
    display: inline-block;
  }

  .profile-img-circle {
    position: absolute;
    width: 52px;
    height: 52px;
    line-height: 52px;
    border-radius: 50%;
    border: 1px solid #cacedcfa;
    display: inline-block;
    margin-left: -10px;
    margin-top: -5px;
  }

  img#profileImg {
      margin-top: -4px;
      margin-left: 0px;
      border-radius: 25px;
  }






a.menuLink:focus, a.menuLink:hover {
    color: #fff;
    text-decoration: underline;
    outline: none;
}

h1#navLogo {
    padding-right: 25px;
}

#navLogo {
    left: 10%;
}

#landing-navbar-collapse-main {
    background: #fefefe;
}

.navbar-fixed-top, .navbar-fixed-bottom {
    position: absolute;
}

.navbar-inverse .btn-link:hover {
    color: #9d9d9d;
}

.navbar-inverse .btn-link {
    color: #444;
}

.navigationLinks > a {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
}

#nav1.col-md-1, #nav2.col-md-1, #nav3.col-md-1 {
    margin-right: auto;
    margin-left: auto;
    width: 12.5%;
}

.navbar-right {
    /* width: 100%; */
    margin-right: -15px;
    max-height: 60px;
    /* margin-right: 10%; */
    position: absolute;
    right: 7vw;
    width: 40%;
    top: 5px;
}

.navlink:hover {
    border-bottom: 2px solid #6ec35e;
    margin-bottom: 5px;
}

.nav > li {
    position: relative;
    display: inline-flex;
    width: auto;
}

.navbar-nav > li > a {
    line-height: 30px;
    color: #999;
    display: inline-flex;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
}

#userMenuDropdown.dropdown {
    top: 15px;
    margin-top: -75px;
    margin-left: 135px;
}

a.dropdown-item {
    display: block;
    border-bottom: 1px solid lightgrey;
    padding: 10px;
}

.navbar-right .dropdown-menu {
    right: 0!important;
    left: auto;
    padding: 10%;
    min-width: 250px;
}

#notificationIcon i.material-icons {
    color: #888;
}

#notificationIcon:hover {
    background: none;
}

#link.navbar-inverse #link.navbar-nav > li > a:hover, #link.navbar-inverse #link.navbar-nav > li > a:focus {
    color: #cccccc;
    background-color: transparent;
}

.navbar-inverse {
    background: #fff;
    margin-left: 0px;
    padding-right: 0%;
    border-color: #f9f9f9;
    height: 80px;
    -webkit-box-shadow: 0px 0px 1px #a2a2a2;
    -moz-box-shadow: 0px 0px 1px #a2a2a2;
    box-shadow: 0px 0px 1px #a2a2a2;
    z-index: 1;
}

.menuLink {
    color: #fff;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 300;
    display: block;
    height: 40px;
    line-height: 40px;
    margin-top: -10px;
    padding-left: 0px;
    text-align: center;
    padding-top: 5px;
}

.menuLink:hover, .menuLink a:hover, .menuLink a:focus, .menuLink a:visited, .menuLink.active {
    color: #fff !important;
    text-decoration: underline;
}

/* .menuLink.active {
    color: #fff !important;
    text-decoration: underline;
    text-decoration-style: double;
} */

#partnerLogoNav {
    position: absolute;
    left: 10%;
    top: 0px;
}

.logo {
    margin: 30px;
    font-family: Ubuntu;
    font-weight: 700;
    color: #29A79F;
    font-size: 34px;
    /* position: absolute;
    left: 10%;
    top: 23px; */
}

.navbar-inverse .navbar-toggle .icon-bar {
    background-color: #fff;
    margin-right: 0;
    width: 100%;
}

.navbar-inverse .navbar-toggle .icon-bar {
    background-color: #145067;
    margin-right: 0;
    width: 100%;
    height: 4px;
    font-weight: 600;
}

.navbar-inverse .navbar-toggle {
    border: none !important;
}

.navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
    background-color: blue;
}

button#mainToggle {
    color: #fff;
}

#desktop-nav {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 300px;
    background: #23273a;
}

#listingSearch.form-control {
    margin-left: 0px;
    margin-top: -2px;
    height: 40px;
    border: none;
    box-shadow: none;
    width: 30%;
    z-index: 0;
    padding-left: 45px;
    border-bottom: 2px solid #5453712e;
    border-radius: 1px;
    position: absolute;
    left: 35%;
    top: 20px;
}

#loginPartial {
    padding: 0; 
    float:right
}

#lowerNav {
    background: #1d4354d1; 
    height: auto;
    border-bottom: 1px solid #f9f9f9; 
    padding-left: 10%; 
    padding-right: 10%;
    padding-top: 0;
}

#listingSearch {
    background: none;
}

.icon-bar {
    background-color: #fff;
}

.container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse {
    margin-right: -15px;
    margin-left: -15px;
    background: #23273a;
    margin-bottom: -5px;
}

@media only screen and (max-width: 1000px) {
    #navbarHeaderDiv {
        display: block;
    }

    #navContainer {
        height: 80px;
        margin-bottom: 25px;
    }

    .logo {
        left: 7%;
    }

    .navbar-right {
        display: none;
    }

    /* #desktop-nav {
        display: none;
    } */

    #listingSearch {
        background: none;
        display: block;
        background: #fff;
        position: absolute;
        top: 85px;
        left: 2px;
        right: 2px;
        width: 99%;
        line-height: 60px;
        height: 50px;
    }

    #listingSearch.form-control {
        padding-left: 19% !important;
        z-index: 0;
        position: inherit;
        border: 2px solid #d8d6d6;
        border-left: none;
        border-right: none;
        height: 9.5vh !important;
        line-height: 9.5vh;
        width: 100%;
        margin: auto;
        font-size: 20px;
    }

    #searchBar.material-icons {
        font-size: 34px;
        z-index: 9999;
        margin: 0;
        top: 0;
        padding: 0;
        left: 5%;
        line-height: 9.5vh;
        vertical-align: middle;
    }

    .container>.navbar-collapse {
        margin: 0;
    }

    #mobile-nav {
        z-index: 99999;
        display: block;
        border: none;
        background: #ffffff;
        float: left;
        width:100%;
        left: 0;
        right: 0;
        margin: 0;
    }
    
    #mobile-nav ul {
        padding: 0; 
        width:100%; 
        margin: auto;
        background: #fff;
    }

    #mobile-nav li {
        padding-left: 10%;
        font-size: 20px;
        display: block;
        padding: 0;
        background: #fff;
        height: 8.5vh;
        line-height: 8.5vh;
    }

    .mobileLI {
        border-bottom: 1px solid #c0c0c0;
        padding-left: 5%!important;
    }
    
    #mobile-search {
        display: block;
        border: none;
        box-shadow: none;
        padding: 0;
        background: none;
    }
    
    #mobile-nav a {
        color: #415869;
        font-size: 16px;
        font-weight: 600;
        font-family: raleway;
        padding-left: 10px!important;
    }

    .clear-mobile {
        line-height: 0px;
        font-size: 32px;
        color: #145067;
        font-weight: 600;
        font-family: ubuntu;
    }

    .mobileLI .material-icons {
        font-size: 24px;
        margin-right: 0px;
        color: #23283a9c;
        float: left;
        margin-top: auto;
        margin-right: 10px;
        margin-left: 0px;
        line-height: 8.5vh;
        padding: 0;
    }

    #landing-navbar-collapse-main {
        height: 0px;
        padding: 0;
        overflow: hidden;
        position: fixed;
        z-index: 999;
        left: 0;
        right: 0;
        background: #fff;
        padding-bottom: 100vh;
        border-top: 1px solid lightgrey;
        margin-top: 1vh;
        z-index: 999999;
    }

    .navbar-inverse {
        position: relative;
        width: 100%;
    }
}