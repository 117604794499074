ol#noSearchHits li {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 10px;
}

.hit-title div {
    display: none;
}

.ais-RefinementList ::placeholder {
    color: #666;
    font-weight: 600;
}

.ais-SearchBox { margin: 1em 0; }
.ais-Pagination { margin-top: 1em }
.left-panel { float: left; width: 250px; }
.right-panel { margin-left: 260px; }
.ais-InstantSearch { max-width: 960px; overflow: hidden; margin: 0 auto }

.ais-Hits-item { 
    margin-bottom: 1em; 
    width: calc(50% - 1rem); 
    padding: 2rem!important;
    background: #fdfdfd; 
}
.ais-Hits-item img { margin-right: 1em }
.hit-name { margin-bottom: .5em }
.hit-description { color: #888; font-size: 14px; margin-bottom: .5em }

#searchListingsSearchBar .ais-Hits-item { 
    margin-bottom: 1em; 
    width: 100%; 
    padding: 2rem!important;
    background: transparent; 
}

#algoliaSearchBox {
    display: inline;
}

.ais-Stats-text {
    font-size: 1.2rem!important;
}

.ais-Stats {
    line-height: 25px;
    margin-left: 3px;
    margin-top: 10px;
    color: #5a5e9a!important;
}

input.ais-SearchBox-input {
    height: 50px;
    padding-left: 3.5rem;
    font-size: 16px;
    border: 1px solid #d6d6e7;
    border-radius: 3px;
    box-shadow: inset 0 1px 4px 0 rgba(119,122,175,.3);
    overflow: hidden;
    transition: all .1s ease-in-out;
}

.ais-SearchBox {
    width: -webkit-calc(100% - 60px);
    width: -moz-calc(100% - 60px);
    width: calc(100% - 60px);
    margin-top: 0;
    margin-right: 10px;
    float: left;
    /* float: left; */
}

.ais-SearchBox-reset {
    right: 1rem!important;
}

.ais-SearchBox-submit {
    left: 1rem!important;
}

.ais-VoiceSearch-status {
    display: none;
}

.ais-Hits {
    clear: both;
}

#noSearchResults {
    margin-bottom: 1em;
    width: calc(50% - 1rem);
    padding: 2rem!important;
    background: #fdfdfd;
    margin-top: 1rem;
    margin-left: 0;
    margin-bottom: 3rem;
    padding: 1rem;
    width: 100%;
    border: 1px solid #c4c8d8;
    box-shadow: 0 2px 5px 0px #e3e5ec;
}

#noSearchResults h4 {
    font-weight: 400;
    color: #3a4570;
}

h6#hitCategory, #clientRegistration .categoryTag {
    background: #316a9229;
    border-radius: 2px;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 0;
    padding: 10px;
    color: #555;
}

.hit-title {
    font-size: 18px;
    font-family: open sans;
    font-weight: 600;
    max-width: 700px;
}

.hit-agency {
    color: #888;
    font-size: 16px;
    margin-bottom: .5em;
}

.hit-funding {
    color: #666;
    font-family: ubuntu;
    font-weight: 600;
    font-size: 15px;
    line-height: 25px;
}

.hit-funding p {
    margin: 0;
}

.hit-category {
    margin-bottom: 10px!important;
}

.ais-VoiceSearch-button {
    padding: 0!important;
}

.ais-VoiceSearch-button svg {
    border: 1px solid lightgrey;
    display: inline;
    height: 50px;
    width: 50px;
    padding: 10px;
    border-radius: 5px;
    --text-opacity: 1;
    color: #5a5e9a!important;
    color: rgba(90,94,154,var(--text-opacity))!important;
    box-shadow: inset 0 1px 4px 0 rgba(119,122,175,.2);
}

.ais-VoiceSearch-button svg:hover {
    background: #f9f9f9;
}

.ais-VoiceSearch-button:hover {
    cursor: pointer;
    background: none!important;
}

a.ais-Pagination-link.ais-Pagination-link--selected {
    color: #fff;
    background-color: #21928a;
    background-image: linear-gradient(-180deg,#808080,#566160);
    border: 1px solid #6f6f6f;
    box-shadow: inset 0 1px 0 0 hsla(0,0%,100%,.06), 0 1px 0 0 rgba(35,38,59,.1);
}

.ais-Pagination-link {
    padding: 0.3rem 0.6rem;
    display: block;
    border: 1px solid #c4c8d8;
    border-radius: 0px!important;
    transition: background-color 0.2s ease-out;
    font-size: 16px!important;
    width: 40px;
    height: 40px;
    line-height: 30px;
    text-align: center;
    margin-right: -1px!important;
    color: #555!important;
}

a.ais-Pagination-link.ais-Pagination-link--selected {
    color: #fff!important;
}

.ais-Pagination-item {
    margin-left: 0!important;
    border: none;
    font-size: 14px!important;
}

.ais-Pagination-list {
    justify-content: center;
    margin-bottom: 20px!important;
}

mark, .mark {
    background: none!important;
    color: #7fa7ca;
    padding: 0!important;
    background: #007eff;
}

h1#searchHeader {
    font-family: ubuntu;
    color: #3a4570;
    font-size: 36px;
    margin-top: -25px;
    font-weight: 500;
}

p#numRecords {
    color: #5a5e9a;
    font-family: open sans;
    display: inline;
    margin-right: 25px;
    line-height: 40px;
    font-size: 15px;
    margin-left: 2px;
    line-height: 50px;
}

.verifiedAward {
    height: 18px;
    width: 18px;
    color: #fff;
    background: #66dc9b;
    border-radius: 9px;
    padding: 2px;
    margin-left: 10px;
    margin-bottom: -3px;
}

/* .hitActions .material-icons {
    border: 1px solid #d3d3d38a;
    float: right;
    width: 35px;
    height: 35px;
    padding: 7px;
    font-size: 20px;
    margin-left: 7px;
    margin-right: 0;
    margin-top: 3px;
    border-radius: 5px;
    --text-opacity: 1;
    color: #5a5e9acc!important;
    color: rgba(90,94,154,var(--text-opacity))!important;
    box-shadow: inset 0 1px 4px 0 rgba(119,122,175,.1);
} */

.hitActions .material-icons {
    color: rgba(90,94,154,var(--text-opacity))!important;
    padding-left: 14px;
}
























#searchResultsReturned {
    /* -webkit-box-shadow: 0 1px 6px rgba(57,73,76,0.35);
    box-shadow: 0 1px 6px rgba(57,73,76,0.35); */
    padding: 0;
}

#searchListingsNavigation {
    background: #fafafa;
    /* padding: 5%; */
    border-bottom: 1px solid lightgrey;
}

#searchListingsNavigationTop {
    background: #fff;
    padding-left: 5%;
    border-bottom: 1px solid lightgrey;
}

#searchListingsNavigationTop h5 {
    display: inline-block;
    margin-top: 25px;
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 30px;
    padding-bottom: 7px;
    font-size: 16px;
}

.searchListingsTabs {
    border-bottom: 1px solid lightgrey;
}

.searchListingsTabs h5 {
    color: #666;
    font-weight: 600;
}

h5.navigationTop {
    color: #666;
    border: none;
    cursor: pointer;
}

h5.navigationTopActive {
    color: #29A;
    border-bottom: 3px solid #29A;
    cursor: pointer;
}

h5.navigationTop:hover {
    color: #29A;
    border-bottom: 3px solid #29A;
    cursor: pointer;
}

.filterCheckMark {
    margin-top: 30px; 
    margin-bottom: 30px;
}

.filterCheckMark div {
    margin-top: 10px;
}

.filterCheckMark label {
    font-weight: normal;
    margin-left: 10px;
}

.filterCheckMark input[type="checkbox"] {
    width: 0;
    height: 0;
    opacity: 0;
}

.filterCheckMark label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
}

.checkmark {
    background: #29A79F;
    padding: 3.5px 11.2px;
    color: white;
    border-radius: 5px;
}

.checkmark-active {
    background: #29A79F;
    padding: 3.5px 6px;
    color: white;
    border-radius: 5px;
    font-size: 14px;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger:focus {
    outline: none;
}

h1#mobileSearchHeader {
    display: none!important;
}

@media only screen and (max-width: 768px) {
    h1#mobileSearchHeader {
        display: block!important;
        font-family: ubuntu;
        font-size: 30px;
        Color: #fff;
        background: teal;
        padding: 10% 10%;
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
    }

    #searchListingsSearchBar {
        width: 100%!important;
    }
}