button.dropdown-item {
    background: none;
    border: none;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid lightgrey;
}

.btn.active, .btn:active {
    box-shadow: none;
}

a.dropdown-item {
    display: block;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    margin-top: -10px;
    float: right;
    margin-right: 25px;
    border-bottom: 15px solid white;
  }

/* .login-partial-dropdown.dropdown-menu.show {
    left: auto!important;
    right: 0!important;
    padding: 0;
    margin-right: -17px;
    margin-top: -10px;
    -webkit-box-shadow: 0 12px 24px 10px rgba(0,0,0,.45);
    box-shadow: 0 12px 24px 10px rgba(0, 0, 0, 0.45);
} */

.login-partial-dropdown.dropdown-menu.show {
    left: auto!important;
    right: 0;
    padding: 0;
    margin-top: -50px!important;
    width: 100%;
    min-width: 260px;
    box-shadow: 0 12px 24px 10px rgba(0, 0, 0, 0.45);
}

button#accountInfoDropdownHeader {
    text-align: center;
    font-family: raleway;
    line-height: 50px;
    font-size: 16px;
}

a.accountDropdownLink.dropdown-item {
    font-family: open sans;
    font-weight: 600;
    line-height: 30px;
    font-size: 11px;
    background: #f7f7f7;
    color: #555;
}

a.accountDropdownLink.dropdown-item:hover {
    background: #f1f1f1;
}

p#loginPartialNameDropdown {
    display: flex;
    font-size: 13px;
    font-family: raleway;
}

i#loginPartialExpand {
    float: right;
    margin: 0;
    color: #999;
    margin-left: 25px;
    margin-top: -10px;
}

#userMenuDropdown button {
    background: none!important;
}

#userNotificationDropdown i {
    margin-top: 11px;
    margin-right: 0;
}

#userNotificationDropdown button {
    background: none!important;
}