/* #stripeCheckout {
    background: #efefef;
} */

input#coupon {
    margin: 0; 
    margin-right: 10px;
    height: 50px;
}

h1#checkoutPortalTitle {
    font-family: ubuntu;
    text-align: center;
    font-size: 48px;
    color: #3a4570;
}

h1#checkoutTry14 {
    text-align: center;
    font-size: 32px;
    font-family: raleway;
    font-weight: 400;
    color: #444;
    margin-bottom: -25px;
}

hr#portalPaywallTitle {
    width: 50%;
    margin-top: 35px;
}

button#productButtonGW {
    background: #29a79f;
    margin:auto;
    width: 100%;
    margin-top: 5%;
    height: 50px;
}

button#productButtonGW:hover {
    background: teal;
}

h1#navLogoCheckout {
    height: 80px;
    cursor: pointer;
    display: block;
    position: relative;
}



#stripePaywallCheckout {
    width: 100%;
    margin: auto;
}

#productDIY, #productGW, #productRS {
    /* border: 1px solid lightgrey; */
    /* background: #fff; */
    /* border-radius: 2px; */
    height: auto;
    padding-bottom: 150px;
    width: 100%;
    margin:auto;
    margin-top: 0;
    /* margin-left: 10px;
    margin-right: 10px; */
    /* box-shadow: 0 1px 5px -1px rgba(47,54,57,.07), 0 0 5px 0 rgba(47,54,57,.06), 0 1px 10px 0 rgba(47,54,57,.05); */
}

#productSelectRow {
    background: #fff;
    border-radius: 3px;
    border: 1px solid lightgrey;
    border: 1px solid #c4c8d8;
    box-shadow: 0 2px 5px 0 #cecece;
    padding: 5%;
}

#productGW {
    height: auto;
    padding-bottom: 150px;
    /* border: 3px solid #0080806e!important; */
    margin-top: 0px;
}

h2#productTitle, h2#productTitleMain {
    margin-left: 25px;
    margin-right: 25px;
    font-weight: 600;
    font-size: 2em;
    line-height: 1.25em;
}

h2#productTitleMain {
    margin-top: 25px;
    color: #3a4570;
    font-weight: 600;
    font-family: open sans;
}

h2#productPrice {
    /* font-family: ubuntu; */
    margin-left: 25px;
    margin-right: 25px;
    font-weight: 600;
    font-size: 1.75em;
    line-height: 1.25em;
    color: #555;
}

h2#productPriceFree {
    /* font-family: ubuntu; */
    margin-left: 25px;
    margin-right: 25px;
    font-weight: 600;
    font-size: 2.25em;
    line-height: 1.25em;
    color: #555;
}

p#productDesc, p#productDescIncluded {
    /* font-family: ubuntu; */
    margin-left: 25px;
    margin-right: 25px;
    font-weight: 300;
    font-size: 1.25em;
    line-height: 1.25em;
    color: #444;
    letter-spacing: .08em;
}

p#productDesc {
    height: auto;
}

hr#productHR {
    width: 90%;
    margin-top: 10%;
}

/* h1#getStartedProducts {
    margin-left: 5%;
    margin-bottom: 5%;
    margin-top: -7%;
    font-family: ubuntu;
    color: #47506f;
    font-size: 36px;
    margin-top: 0;
    line-height: 1.15em;
} */

#productSelectRow {
    margin: auto;
    width: 100%;
}

input#productButtonGW, input#productButtonRS, input#productButtonDIY {
    position: absolute;
    bottom: 5%;
    width: 85%;
    left: 7.5%;
    right: 7.5%;
    height: 50px;
    border-radius: 2px;
    border: none;
    background: #444;
    color: #fff;
    /* font-family: ubuntu; */
    font-weight: 300;
    font-size: 20px;
}

input#productButtonGW {
    /* background: #53b4ac!important; */
}

i#productCheckmark.material-icons {
    color: teal;
    margin-left: -15px;
    margin-right: 10px;
    vertical-align: bottom;
    float: left;
}

p#productCheckP {
    /* margin-top: -25px; */
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 15px;
    line-height: 1.25em;
    /* font-family: ubuntu; */
    font-weight: 400;
}

li#productListItem {
    display: block;
    margin-left: -10px;
}

/* #needHelpProductsRow {
    margin-top: 10%;
    background: #68b9b2;;
    height: 450px;
    position: absolute;
    left: 0;
    right: 0;
} */

#needHelpProductsRow {
    margin-top: 100px;
    height: auto;
    left: 5%;
    right: 5%;
}

#grantResourcesRow {
    margin-top: -10%;
    margin-bottom: 20%;
    height: 100%;
    left: 5%;
    right: 5%;
}

h1#maximizeAward {
    /* font-family: ubuntu; */
    font-weight: 600;
    text-align: center;
    margin-top: 10%;
}

#maximizeAwardDiv {
    max-width: 650px;
    margin: auto;
    /* margin-top: 800px; */
}

#getHelpChoosingDiv {
    height: auto;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
}

#getHelpChoosingDiv p {
    /* font-family: ubuntu; */
    font-weight: 300;
    font-size: 1.25em;
    line-height: 1.25em;
    color: #444;
    /* letter-spacing: .05em; */
}

#getHelpChoosingDiv h1 {
    /* font-family: ubuntu; */
    font-weight: 600;
    color: #444;
    font-size: 2.5em;
}

#getHelpChoosingDiv input, #maximizeAwardDiv input  {
    /* width: 85%;
    left: 7.5%;
    right: 7.5%; */
    height: 50px;
    border-radius: 2px;
    border: none;
    background: #444;
    color: #fff;
    /* font-family: ubuntu; */
    font-weight: 300;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 5%;
}

a#grantResourceGuide {
    height: auto;
    border-radius: 2px;
    border: none;
    background: #444;
    color: #fff;
    /* font-family: ubuntu; */
    font-weight: 300;
    font-size: 16px;
    padding-left: 25px;
    padding-right: 25px;
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 5%;
}

h1#maximizeFundingH1 {
    /* font-family: ubuntu; */
    font-weight: 600;
    color: #333;
    text-align: center;
    font-size: 4em;
    margin-top: 10%;
}

h1#stillNeedHelpH1 {
    /* font-family: ubuntu; */
    font-weight: 600;
    color: #333;
    text-align: center;
    font-size: 3em;
    margin-top: 10%;
}

#maximizeAwardDiv p {
    /* font-family: ubuntu; */
    text-align: center;
    font-size: 20px;
    margin-top: 5%;
    color: #444;
}

#maximizeAwardDiv input {
    margin: auto;
    text-align: center;
    display: block;
    margin-top: 5%;
    background: #2ca79e;
}

p#didYouKnow {
    font-weight: 300;
    color: #333;
    margin-top: 10%;
}

p#fundingRangeLikeYou {
    font-weight: 600!important;
    color: #555;
    margin-bottom: 7%;
}

@media only screen and (max-width: 1000px) {
    #productSelectRow {
        background: none;
        border: none;
        box-shadow: none;
    }
}

@media only screen and (max-width: 768px) {
    img#grantHeroIMG {
        height: 300px;
        margin: auto;
        text-align: center;
        display: block;
    }

    #getHelpChoosingDiv {
        height: auto;
    }

    #maximizeAwardDiv {
        margin: 5%;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    h1#maximizeFundingH1 {
        font-size: 2.5em;
        letter-spacing: -0.025em;
    }

    #stripePaywallCheckout {
        width: 95%;
    }
}