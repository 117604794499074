p {
    font-size: 14px;
}

.organizationList:hover {
    /* background-color: #eee; */
    text-decoration: none!important;
  }

.linkListing:hover {
    text-decoration: underline;
    color: blue
}

p {
    font-size: 16px;
}

#organization_exists_list {
    margin: auto;
    border: 1px solid black;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    background: #fff;
    max-width: 500px;
}

#organization_exists_list #existingOrgDiv {
    border-bottom: 1px solid black;
}

.tagList {
    background: #2a96a733;
    padding: 5px 10px;
    display: inline-block;
    border-radius: 5px;
    border: 1px solid #2a96a799;
    margin-right: 10px;
    margin-bottom: 10px;

}

.iconbutton:hover {
    background-color: grey;
}