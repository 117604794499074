#messageWindow {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background: #fff;
    /* padding: 2.5%; */
    border: 1px solid lightgrey;
    border-radius: 10px;
    max-width: 500px;
    height: 650px;
    max-height: 80%;
    /* overflow-x: scroll; */
    border-bottom: 1px solid lightgrey;
    /* display: inline; */
    width: 100%;
    z-index: 999;
}

#messageWindowHeader {
    border-bottom: 1px solid lightgrey;
    /* display: inline; */
    /* background-color: #0C7069; */
    padding: 2.5%;
}

#messageWindowHeader h1 {
    display: inline;
    font-size: 24px;
    margin-left: 15px;
    line-height: 50px;
    font-weight: 500;
    font-family: 'Ubuntu';
    color: white;
    /* margin-top: 5px; */
    /* padding-top: 25px;*/
}

/* .chatContainer_input {
    overflow: scroll;
    height: 400px;
    padding: 2.5%;
} */

.chatContainer_input {
    overflow: scroll;
    /* max-height: 60vh; */
    /* margin-bottom: 150px; */
    padding: 10px;
    /* padding-bottom: 150px; */
    height: calc(66vh - 60px);
}

.chatContainer_input_focus{
    overflow: scroll;
    /* max-height: 60vh; */
    /* margin-bottom: 150px; */
    padding: 10px;
    /* padding-bottom: 150px; */
    height: calc(66vh - 60px - 50px);
}

/* #messageWindowChat { */
    /* overflow: scroll;
    height: 400px; */
/* } */

/* #messageWindowInput {
    border-top: 1px solid lightgrey;
    padding: 2.5%;
} */

#messageWindowInput {
    border: 1px solid #d3d3d3;
    padding: 25px;
    position: fixed;
    bottom: 0;
    right: 10px;
    width: 500px;
    background: #fff;
}

#messageBox {
    background: transparent;
    padding: 5%;
    border: none;
    cursor: pointer;
}

.searchMessageContainer {
    width: 100%;
    border: 1px solid #2B2C2D;
    border-radius: 5px;
}

.searchMessageinput {
    border: none;
    width: 90%;
    background-color: transparent;
}

#messageBox:hover {
    background: #dedede!important;
}

h1#yourMessages {
    margin: auto;
    font-weight: 500;
    font-size: 36px;
    font-family: 'Ubuntu';
    margin-bottom: 1.5%;
    color: #1e4353;
}

#messagesDiv {
    max-width: 650px;
    width: 90%;
    margin: auto;
}

#noMoreMessagesDiv {
    background: #fff;
    min-height: 100px;
    line-height: 100px;
    font-size: 14px;
    font-weight: 600;
    color: #777;
    text-align: center;
    padding: 2.5%;
    border: 1px solid lightgrey
}

button#closeChatButton {
    float: right;
    background: none;
    font-size: 18px;
    font-weight: 500;
    color: white;
    background: none;
    line-height: 40px;
}

#chatInputBox {
    margin-top: 15px;
}

.chat_heading {
    margin-top: 15px;
    padding: 10px;
    background-color: blue;
    color: white;
}

.chat_heading img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    vertical-align: top;
    margin-right: 15px;
}

.chat_heading h1 {
    display: inline-block;
    margin: 0;
}

.message_input {
    margin-right: 0px;
    margin-bottom: 0 !important;
    width: 82%;
    height: 50px;
    padding: 5px;
    font-weight: 500;
    border-radius: 5px;
}

.message_input_focus {
    margin-right: 0px;
    margin-bottom: 0 !important;
    width: 70%;
    height: 100px;
    padding: 5px;
    font-weight: 500;
}

.message_send {
    float: right;
    padding: 6px 15px;
    height: 50px;
    width: 15%;
    /* background-color: #0C7069; */
    color: white;
    border: 0;
    border-radius: 5px;
}

.messages_box {
    /* border: 1px solid black; */
    padding: 15px;
}

.messages_box div {
    overflow: auto;
}

.messages_box p {
    padding: 5px 15px;
    border-radius: 15px;
    max-width: 90%;
}

.outgoing {
    float: right;
    background-color: #4161bb;
    color: white;
    border-radius: 10px!important;
}

.incoming {
    float: left;
    background-color: gray;
    color: white;
    border-radius: 10px!important;
}

.input_error {
    color: red;
    font-size: 14px;
}

form > div {
    margin-top: 15px;
}

input {
    padding: 5px 10px;
}

textarea {
    padding: 5px 10px;
}

/* fyc */
.messagesHeader {
    font-family: ubuntu;
    color: black;
    font-size: 30px;
    font-weight: 600;
}

.SideMessageContainer {
    padding: 2%;
}
.SideMessageContainer:hover {
    background-color: #347ae41a;
    border: 1px solid #347ae41a;
    border-radius: 10px;
}

/* Bigger Tablets */

@media only screen and (min-width: 1000px) and (max-width: 1200px) {
    .chatContainer_input{
        height: calc(66vh - 60px);
    }
    .chatContainer_input_focus{
        height: calc(66vh - 60px - 50px);
    } 
}

/* Tablets */

@media only screen and (max-width: 1000px) {
    #messageBox p {
        font-size: 14px !important;
    }

    #messageBox img {
        width: 50px;
        height: 50px;
    }

    #name{
        font-size: 22px !important;
    }

    #messageWindowInput {
        padding: 20px !important;
    }

    .chatContainer_input{
        height: calc(68vh - 60px);
    }

    .chatContainer_input_focus{
        height: calc(68vh - 60px - 50px);
    }
}

@media only screen and (max-width: 750px) {

    #messageBox img {
        width: 40px;
        height: 40px;
    }

    #name{
        font-size: 20px !important;
    }
    
    #messageBox p {
        width: 100%;
        margin-bottom: -20px;
        text-align: end;
    }

    .chatContainer_input {
        height: 53vh;
    }
    .chatContainer_input_focus {
        height: calc(53vh - 50px);
    }
}

/* Mobile */

@media only screen and (max-width: 520px){
    #messageWindow,
    #messageWindowInput,
    #chatboxDropdown {
        width: 96%;
    }
}

@media only screen and (max-width: 480px) {

    #messageWindow {
        max-height: 85vh !important;
    }
    
    #messagesDiv h1{
        font-size: 26px;
        margin-top: -25px;
        margin-bottom: 10px;
    }
   
    #messageBox .row{
        display: flex;
        flex-direction: column;
    }
   
    #messageBox p {
        font-size: 16px !important;
        text-align: center;
        margin-bottom: 20px;
    }

    #messageBox img {
        width: 50px;
        height: 50px;
        margin: auto;
    }

    #messageBox h5 {
        margin-top: 10px !important; 
        text-align: center;
    }

    #messageBox .row div {
        max-width: 100% !important;
        margin: auto !important;
    }

    .chatContainer_input {
        height: 60vh;
    }
    .chatContainer_input_focus {
        height: calc(60vh - 50px);
    }

    #messageWindowHeader img {
        width: 40px !important;
        height: 40px;
    }

    #messageWindowHeader h1 {
       font-size: 22px;
    }

    .message_send {
        padding: 1px 1px;
        width: 25%;
    }

    #messageWindowInput{
        padding: 20px !important;
    }
}