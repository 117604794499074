#clientRegistration input,
#clientRegistration textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    /* height: 50px!important; */
    border-radius: 5px!important;
    padding: 10px!important;
    /* min-width: 100%!important; */
    width: 100%;
    border: 1px solid lightgrey!important;
}

p#mobileRegPoweredByP {
    font-family: montserrat;
    font-weight: 600;
    color: #29a79f;;
    text-align: center;
    font-style: italic;
    font-size: 20px;
    margin-bottom: 10%;
}

#clientRegistration textarea {
    height: 150px!important;
}

#clientRegistration {
    height: 100%;
    width: 100%;
    /* position: fixed;
    top: 0;
    left: 0; */
    margin-left:0px;
    margin-right:0px;
    /* z-index: 99; */
}

#rightRegistration {
    padding-bottom: 5%; 
    /* overflow-y: auto;  */
    /* overflow-x: hidden;  */
    height: 100%; 
    padding-left: 0;
    background-color: white;
}

#clientRegistration textarea {
    height: 200px;
}

#clientRegistration h2 {
    margin-bottom: 50px;
    font-size: 16px;
}

.nextButton {
    cursor: pointer;
    background: #29A79F;
    border-radius: 5px;
    color: white;
    padding: 5px 15%;
    border: 1px solid #29A79F;
    float: right;
    margin-bottom: 20px;
}

.backButton {
    cursor: pointer;
    background: white;
    border-radius: 5px;
    color: #29A79F;
    padding: 5px 15%;
    border: 1px solid #29A79F;
    float: left; 
}

h1#portalLogo, h1#regPortalLogo {
    position: absolute;
    top: -10px;
    left: 25px;
    font-family: Ubuntu;
    font-weight: 700;
    color: #29A79F;
    font-size: 3.5rem!important;
}

.registrationLogo {
    font-family: Ubuntu;
    color: white;
    text-align: center;
}

#registerPassword {
    display: inline-block; 
    width: 90%!important;
}

/*navigation*/
#clientRegistration .navigation {
    background: linear-gradient(0deg, rgba(10, 80, 67, 0.48), rgb(29, 84, 86)), url(https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/opengrants_login_rocket.png);
    height: 100%;
    background-size: contain;
    background-position: bottom;
    /* padding-right: 0; */
}

.navigation > nav {
    margin: auto;
    padding-top: 50px;
    padding-left: 40px;
    width: 250px;
}

.navigation > nav > a {
    display: block;
    color: #fff;
    font-weight: bold;
    padding: 10px;
    border-left: 5px solid white;
}

.navigation > nav > a:hover {
    text-decoration: none;
    color: #aaaaaa;
}

.navigation-active {
    color: #29A79F !important;
    border-left: 5px solid #29A79F !important;
}

#clientRegistration #navFooter {
    color: #fff; 
    font-size: 28px;
    width: 90%;
}

#clientRegistration #navFooter {
    display: block; 
}

#clientRegistration #navFooter {
    margin-bottom:10px;
}

/*Password Strength Meter */
#opengrantsPassword input {
    font-size: 13px;
    margin-bottom: 5px;
    padding-left: 5px!important;
    color: #555!important;
    font-weight: 400;
}

.ReactPasswordStrength.passwordStrength {
    border: 1px solid lightgrey!important;
    font-size: 12px!important;
    margin-top: 6%;
    border-radius: 0;
    box-shadow: none;
    padding-left: 5px!important;
    background: #eee;
    height: 55px;
}

#form_0 .ReactPasswordStrength-input {
    font-size: 13px;
    margin-bottom: 5px;
    background: #eee;
    padding-left: 5px!important;
}

.ReactPasswordStrength-strength-bar {
    box-sizing: border-box;
    height: 2px;
    position: absolute!important;
    bottom: -7px!important;
    left: 1px!important;
    top: auto;
    right: auto;
    transition: width 300ms ease-out;
    width: 0;
}

.ReactPasswordStrength.is-strength-0 .ReactPasswordStrength-strength-bar,
.ReactPasswordStrength.is-strength-1 .ReactPasswordStrength-strength-bar,
.ReactPasswordStrength.is-strength-2 .ReactPasswordStrength-strength-bar,
.ReactPasswordStrength.is-strength-3 .ReactPasswordStrength-strength-bar,
.ReactPasswordStrength.is-strength-4 .ReactPasswordStrength-strength-bar {
    width: 100%!important;
}

input.ReactPasswordStrength-input {
    position: absolute;
    bottom: 2px;
    left: 0px;
    padding: 0;
    padding-left: 5px!important;
}

input#category, input#category_Tags {
    height: 50px;
    background: #efefef;
    border-radius: 2px!important;
}

/*form*/
.forms {
    max-width: 550px;
    height: auto;
    padding-top: 5%;
    padding-bottom: 10%;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
}

h1.regHeader {
    font-family:sans-serif;
    font-size: 30px;
    font-weight: 600;
    color:#29A79F;
}

.MuiFormControl-root {
    border: 1px solid lightgrey!important;
    background: #eee;
}

.MuiInput-underline:hover:not(.Mui-disabled):before,
.MuiInput-underline:before {
    border-bottom: none!important;
}

.MuiFormLabel-root.Mui-focused {
    color: #2aa79f;
    margin-top: 5px!important;
}

#portalRegRow label {
    padding-left: 5px;
    /* padding-top: 5px; */
    margin-top: 0px;
    margin-bottom: 5px;
    line-height: 10px;
}

.question_section {
    margin: 20px 0px;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
}

.question_section > label {
    font-weight: normal;
    text-align: left;
    color: #666;
}

.question_section  a {
    margin-top: 25px;
    border: 0px;
    background: none;
    text-decoration: underline;
    color: rgb(35 40 58 / 55%);
    text-align: left;
    font-size: 14px;
}

.question_section .showClearAll {
    display: inline-block;
    color: #777;
    margin-top: 15px;
    margin-left: 5px;
}

.question_section .hideClearAll {
    display: none;
}

.reg-link-list {
    position: absolute;
    right: 5%;
    top: 25px;
}

.reg-link-list a {
    display: block;
    margin-bottom: 2px;
    font-family: sans-serif;
}

i#EIN_number_info, i#DUNS_number_info {
    font-size: 18px;
    color: #999!important;
    float: none;
    margin-left: 10px;
    margin-top: -20px;
}

/* .question_section ul {
    position: absolute;
    z-index: 100;
    width: 100%;
    max-height: 325px;
    max-width: 400px;
    overflow-y: scroll;
    font-size: 12px;
} */

.question_section select {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    height: 50px!important;
    border-radius: 5px!important;
    padding: 10px!important;
    min-width: 100%!important;
    border: 1px solid lightgrey!important;
}

.error {
    color: red;
    margin-top: 5px;
}

.registrationError {
    color: red;
    font-size: 20px;
}

.registerationRequired {
    color: red;
}


/*option lists*/
.optionList {
    background: white;
    z-index: 10;
    position: absolute;
    max-height: 200px;
    overflow-y: scroll;
    display: none;
    box-shadow: 0 0px 10px -5px;
    margin-bottom: 30px;
}

.optionList > div {
    cursor: pointer;
    padding: 5px 10px 5px 10px;
}

.optionList > div:hover {
    background: #33b7ab;
    color: #fff;
}

.optionList-active {
    background: #33b7ab;
    color:#fff;
}

.categoryTag > div {
    background-color: #dee7ed;
    color: #555555;
    border: 0px;
    padding: 0px;
    padding-left: 15px;
    display: inline-block;
    cursor: pointer;
}

.tooltip-arrow-left {
    width: 15px;
    height: 15px;
    transform: rotateZ(45deg);
    border: none!important;
    top: 6px;
    position: absolute;
    left: -7px;
    background: rgba(29,67,84);
}

.button-tooltip {
    position: absolute;
    margin-left: 20px;
    margin-top: -6px;
}

.tooltips label {
    display: inline-block !important;
}

.tooltips .show {
    opacity: 1!important;
}

.tooltips .dropdown-menu {
    box-shadow: 0 0 !important;
    background: rgba(29,67,84);
    color: white;
}

.tooltips .dropdown-item {
    border-bottom: none;
    padding: 0 15px;
}

.tooltips h2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    font-size: 18px !important; 
}

.info-circle {
    background: #777;
    color: white;
    padding: 1px 7px;
    border-radius: 50%;
    margin-left: 5px;
}

h1.mobileRegisterlogo {
    margin: 30px;
    font-size: 32px;
    text-align: left;
    color: #29A79F;
    font-family: ubuntu;
    line-height: 50px;
}

#mobileRegPoweredBy {
    display: none;
}

@media only screen and (max-width: 1000px) {
    h1.mobileRegisterlogo {
        font-size: 32px;
        text-align: center;
        color: #29A79F;
        font-family: ubuntu;
        line-height: 50px;
        /* display:none; */
    }

    h1.regHeader {
        font-size: 24px;
        color: #888;
    }

    h1#regPortalLogo {
        display:none;
    }

    #mobileRegPoweredBy {
        display: block;
    }
    
}

@media only screen and (max-width: 768px) {
    .navigation {
        display: none;
    }

    .reg-link-list {
        display: none;
    }

    .nextButton, .backButton {
        width: 49%;
        padding: 5%!important;
        border-radius: 5px!important;
        margin-top: 15px;
    }
}

#signUpForm .error, #zipInput .error {    
    color: red;
    margin-top: 5px;
    margin-bottom: 0;
    font-family: raleway;
    font-size: 12px;
    height: 5px;
}

#signUpLinkWrite, #signUpLinkHIW {
    display: inline-flex;
    margin-left: 15px;
    font-family: inherit;
    font-weight: 300;
    color: #444;
}

.sweet-loading {
    z-index: 999;
    background: #dde5e8c9!important;
}

a#howItWorks_Signup {
    cursor: pointer;
    text-decoration: none;
    color: #444;
    position: absolute;
    right: 10%;
    top: 25px;
}

h1#howItWorksHeader {
    max-width: 600px; 
    margin: auto;
    display: block;
    text-align: center;
    margin-top: 10%;
    font-size: 50px;
    color: #444;
    font-family: open sans;
    font-weight: 900;
}

h1#howItWorksMidHeader {
    text-align: center;
}

h2#creatingAccountLoader {
    top: 35%;
    position: relative;
    margin: auto;
    text-align: center;
    font-family: ubuntu;
    font-weight: 700;
    color: #555;
    font-style: italic;
}

.css-0 {
    display: block!important;
    margin: auto!important;
    margin-top: 40vh!important;
    width: 165px!important;
}

.MuiInputBase-input {
    font-size: 14px!important;
}

#zipInput .MuiFormControl-root.MuiTextField-root {
    margin-bottom: 5%;
    margin-top: 0!important;
    max-width: 350;
    font-size: 34;
}

output#output {
    font-size: 36px;
    font-family: Raleway;
}

button.signUpContinue {
    background: #2aa79f;
    margin: auto;
    color: #fff;
    height: 80px;
    width: 200px;
    font-size: 20px;
    font-family: raleway;
    border-radius: 2px;
}

.continueButton {
    margin-top: 5%;
    text-align: center;
    margin-bottom: 10vh;
}

button.yesNoButton {
    background: #2aa79f;
    margin: auto;
    margin-left: 1%;
    margin-right: 1%;
    color: #fff;
    height: 80px;
    width: 200px;
    font-size: 20px;
    font-family: raleway;
    border-radius: 2px;
    border: none;
}

button {
    border-color: #fff;
    border: none;
}

hr#signUpSliderHR {
    margin-top: 0;
    margin-bottom: 10px;
    width: 10%;
    border-top: 3px solid #e0dfdf;
}

h1#signUpWelcomeH1 {
    text-align: center;
    color: #2aa79f;
    font-size: 42px;
    margin: auto;
    margin-top: 10%;
    width: 100%;
    margin-bottom: 5%;
    font-family: ubuntu;
    font-size: 32px;
    font-weight: 600;
}

#sliderTerms {
    text-align: center;
    font-size: 14px;
    max-width: 500px;
    margin: auto;
    margin-top: 10%;
}

#signUpPage {
    height: 100%;
    width: 100%;
    /* position: fixed; */
    top: 0;
    left: 0;
    margin-left:0px;
    margin-right:0px;
    z-index: 99;
    /* align-items: stretch; */
    display: flex;
}

#signUpPage > div {
    align-items: stretch;
}

#signUpForm {
    padding: 0;
    padding-top: 10%;
}

#opengrantsPassword input {
    font-size: 13px;
    margin-bottom: 5px;
}

.MuiTypography-body1 {
    font-size: 1.5rem!important;
    font-family: open sans!important;
    line-height: 2!important;
}

/* .MuiSvgIcon-root {
    fill: #29A79F!important;
    width: 2em!important;
    height: 2em!important;
    font-size: 2rem!important;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
} */

#NSF_Category_Selector .MuiFormControl-root {
    margin-top: 0!important;
    display: block;
}

#NSF_Category_Selector .MuiFormControlLabel-root {
    display: block;
}

#focusCheckLeft, #focusCheckRight {
    display: inline-grid;
}

#NSFFocusContinueButton.continueButton,
#SBIRFocusContinueButton.continueButton {
    margin-top: 15%;
    margin-bottom: 25%;
}

#BusinessLocationContinueButton.continueButton {
    margin-top: 10%;
    margin-bottom: 25%;
}


h3#checkAllH3 {
    color: #666;
    font-family: raleway;
    font-size: 18px;
    margin-bottom: -10px;
}

#focusNSFArea {
    max-width: 475px;
    margin-bottom: 5%;
    margin: auto;
}

h3#focusHeader {
    font-family: raleway;
    font-size: 20px;
    color: #29A79F;
}

#alreadySignedUp {
    text-align: center;
    margin-top: 5%;
}

#signUpTerms {
    text-align: center;
    margin-top: 8%;
}

#alreadySignedUp p {
    color: #666;
    font-size: 18px;
    font-family: raleway;
}

#signUpTerms p {
    color: #666;
    font-size: 17px;
    font-family: raleway;
    max-width: 300px;
    margin:auto;
}

button#signUpLetsGo {
    background: #2aa79f;
    color: #fff;
    font-family: raleway;
    font-weight: 300;
    font-size: 16px;
    width: 60%;
    height: 50px;
    border-radius: 2px;
    text-align: center;
    margin: auto;
    display: list-item;
    margin-top: 10%;
}

.MuiFormControl-root {
    width: 100%;
    margin-top: 5%!important;
}

.MuiFormLabel-root {
    font-size: 13px!important;
}

.MuiInputLabel-formControl {
    transform: translate(0, 18px) scale(1);
}

#rightSignUp {
    padding: 5%;
    /* overflow-y: auto; 
    overflow-x: hidden;  */
    height: 100%; 
    background-color: white;
}

h1#signUpTagLine {
    text-align: center;
    color: #333;
    line-height: 1.25em;
    font-family: Montserrat;
    max-width: 750px;
    margin: auto;
    font-weight: 900;
    margin-top: 5%;
}


#signUpPage textarea {
    height: 200px;
}

#signUpPage h2 {
    margin-bottom: 50px;
    font-size: 16px;
}

.nextButton {
    cursor: pointer;
    background: #29A79F;
    border-radius: 15px;
    color: white;
    padding: 5px 15%;
    border: 1px solid #29A79F;
    float: right;
}

.backButton {
    cursor: pointer;
    background: white;
    border-radius: 15px;
    color: #29A79F;
    padding: 5px 15%;
    border: 1px solid #29A79F;
}

.registrationLogo {
    font-family: Ubuntu;
    color: white;
    text-align: center;
}

#registerPassword {
    display: inline-block; 
    width: 90%!important;
}

h1#howItWorks_Signup {
    float: right;
    position: absolute;
    top: 5px;
    right: 10%;
    font-size:14px;
    font-family: open sans;
    font-weight: 400;
    color:#555;
}

/*navigation*/
#signUpPage .navigation {
    background: linear-gradient(0deg,rgba(10, 80, 67, 0.22),#2d898ca6),url(https://s3-us-west-1.amazonaws.com/opengrants.io/opengrants_images/opengrants.io_rocket_blue.png);
    /* background: url(https://s3-us-west-1.amazonaws.com/opengrants.io/opengrants_images/opengrants.io_rocket_blue.png); */
    height: auto;
    background-size: cover;
    background-position: top;
    /* padding-right: 0; */
}

.navigation > nav {
    margin: auto;
    padding-top: 50px;
    padding-left: 40px;
    width: 250px;
}

.navigation > nav > a {
    display: block;
    color: #fff;
    font-weight: bold;
    padding: 10px;
    border-left: 5px solid white;
}

.navigation > nav > a:hover {
    text-decoration: none;
    color: #aaaaaa;
}

.navigation-active {
    color: #29A79F !important;
    border-left: 5px solid #29A79F !important;
}

#signUpPage #navFooter {
    color: #fff; 
    font-size: 28px;
    width: 90%;
}

#signUpPage #navFooter {
    display: block; 
}

#signUpPage #navFooter {
    margin-bottom:10px;
}

/*form*/
/* .forms {
    max-width: 450px;
    height: auto;
    padding-top: 10%;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
} */

h1.regHeader {
    font-family:sans-serif;
    font-size: 30px;
    font-weight: 600;
    color:#29A79F;
}

.question_section {
    margin: 20px 0px;
    position: relative;
}

.question_section > label {
    font-weight: normal;
}

.question_section  a {
    margin-top: 10px;
    border: 0px;
    background: none;
    text-decoration: underline;
    color: rgb(41, 167, 159);
    text-align: left;
}

.question_section .showClearAll {
    display: inline-block;
}

.question_section .hideClearAll {
    display: none;
}

i#EIN_number_info, i#DUNS_number_info {
    font-size: 18px;
    color: #999!important;
    float: none;
    margin-left: 10px;
    margin-top: -20px;
}

/* .question_section ul {
    position: absolute;
    z-index: 100;
    width: 100%;
    max-height: 325px;
    max-width: 400px;
    overflow-y: scroll;
} */

.question_section select {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    height: 50px!important;
    border-radius: 5px!important;
    padding: 10px!important;
    min-width: 100%!important;
    border: 1px solid lightgrey!important;
}

.error {
    color: red;
    margin-top: 5px;
}

.registrationError {
    color: red;
    font-size: 20px;
}

.registerationRequired {
    color: red;
}


/*option lists*/
.optionList {
    background: white;
    z-index: 10;
    position: absolute;
    max-height: 300px;
    overflow-y: scroll;
    display: none;
    box-shadow: 0 3px 15px -5px;
}

.optionList > div {
    cursor: pointer;
    padding: 5px 10px 5px 10px;
}

.optionList > div:hover {
    background: #33b7ab;
    color: #fff;
}

.optionList-active {
    background: #33b7ab;
    color:#fff;
}

.tooltip-arrow-left {
    width: 15px;
    height: 15px;
    transform: rotateZ(45deg);
    border: none!important;
    top: 6px;
    position: absolute;
    left: -7px;
    background: rgba(29,67,84);
}

.button-tooltip {
    position: absolute;
    margin-left: 20px;
    margin-top: -6px;
}

.tooltips label {
    display: inline-block !important;
}

.tooltips .show {
    opacity: 1!important;
}

.tooltips .dropdown-menu {
    box-shadow: 0 0 !important;
    background: rgba(29,67,84);
    color: white;
}

.tooltips .dropdown-item {
    border-bottom: none;
    padding: 0 15px;
}

.tooltips h2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    font-size: 18px !important; 
}

.info-circle {
    background: #777;
    color: white;
    padding: 1px 7px;
    border-radius: 50%;
    margin-left: 5px;
}

#zipInput {
    padding: 5%; 
    border: 1px solid lightgrey;
    border-radius: 3px; 
    min-width: 500px;
    text-align: center;
}



@media only screen and (max-width: 1000px) {
    h1.mobileRegisterlogo {
        font-size: 32px;
        text-align: center;
        color: #29A79F;
        font-family: ubuntu;
        line-height: 50px;
    }

    h1.regHeader {
        font-size: 24px;
        color: #888;
    }
}

@media only screen and (max-width: 992px)
{
    #rightSignUp {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    /* .navigation {
        display: none;
    } */

    .nextButton, .backButton {
        width: 49%;
        padding: 5%!important;
        border-radius: 5px!important;
        margin-top: 15px;
    } */

    #fundingSlider5, #fundingSlider6, #fundingSlider8 {
        margin-top: 5em!important;
    }

    #fundSliderDiv {
        margin-top: 35px;
    }

    output#output {
        margin-bottom: 35px;
    }

    button.yesNoButton {
        width: 40%;
        margin: 10px;
    }

    p#regHelperText {
        margin-left: 25px;
        margin-right: 25px;
    }

    button.signUpContinue {
        margin-top: 35px;
    }

    #zipInput {
        min-width: 90%;
    }

    #focusNSFArea {
        margin: 5%;
    }

    #NSF_Category_Selector .MuiFormControlLabel-root {
        display: flex;
    }

    #signUpLinkWrite {
        display: none!important;
    }

    #zipInput {
        min-width: 250px;
        max-width: 90vw;
    }
}

@media only screen and (max-width: 500px)
{
    /* #fundingSlider1, #fundingSlider2, #fundingSlider3, #fundingSlider4, #fundingSlider5, #fundingSlider6, #fundingSlider7, #fundingSlider8, #fundingSlider9, #fundingSlider10 {
        padding: 5%;
    }

    #fundingSlider4 {
        width: 85vw!important;
    }

    .awssld__controls {
        visibility: inherit;
    }

    .awssld__controls button {
        margin-right: 5px;
    } */

    h1#signUpTagLine {
        margin-top: 10%!important;
    }
}
