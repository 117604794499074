/* .react-expand-collapse__content {
    position: relative;
    overflow: hidden;
}

.react-expand-collapse__body {
    display: inline;
} */

/* expand-collapse button */
/* .react-expand-collapse__button {
    color: #22a7f0;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #fff;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
} */

#listingNotes {
    margin-top: 5%;
    background: #fff;
    border-radius: 3px;
    border: 1px solid lightgrey;
    border: 1px solid #c4c8d8;
    box-shadow: 0 2px 5px 0 #cecece;
    padding: 5%;
    min-height: 500px;
}

.react-expand-collapse__button {
    color: #22a7f0;
    position: absolute;
    bottom: -20px;
    /* right: 20px; */
    width: 100%;
    height: 2em;
    padding-top: 15px;
    text-align: center;
    background-image: -webkit-gradient( linear, left top, left bottom, from(rgba(255, 255, 255, 0.1)), to(rgba(255, 255, 255, 0.9)), color-stop(.5,#ffffff) );
    /* mozilla example - FF3.6+ */
    background-image: -moz-linear-gradient(
        rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.9) 95%
    );
    background-image: #ffffffe8;
    float: right;
    /* background-color: #fff; */
    cursor: pointer;
    /* padding-bottom: 15px; */
    -webkit-tap-highlight-color: transparent;
}

.react-expand-collapse__button:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    margin-top: -50px;
    margin-bottom: 0;
    padding: 0;
}

.react-expand-collapse__button[aria-expanded="false"]:before {
    background: linear-gradient(to bottom, transparent 0, #fff 100%);
}

.react-expand-collapse__content {
    overflow: hidden;
}

/* expanded state */
/* .react-expand-collapse--expanded .react-expand-collapse__button {
    padding-left: 5px;
    position: relative;
    bottom: auto;
    right: auto;
} */

/* .react-expand-collapse--expanded .react-expand-collapse__button:before {
    content: none;
} */

.row#partialBodyRow {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
}

#listingBody {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 9999999;
    overflow-y: scroll;
}

#alertSuccess {
    text-align: center; 
    margin: auto;
}

.backArrow .material-icons {
    float: left;
    color: #29A79F;
    cursor: pointer;
    font-size: 42px;
    margin-left: 0px;
    line-height: 60px;
}

.openListing .material-icons {
    line-height: 60px;
    font-size: 28px;
    margin-left: 0;
}

.openListing {
    cursor: pointer; 
    padding-right: 3%;
}

.material-icons {
    font-size: 24px;
    color: #29A79F;
    float: right;
    width: 24px;
    line-height: 60px;
    margin-left: -10px;
}

#listingSavedButton {
    color: #fff;
    background: #29A79F;
}

#listingSavedButtonIcon, #saveListingButtonIcon {
    float: none;
    margin-right: 5px;
}

#saveListingButtonIcon {
    color: #29A79F;
}

#appDates {
    border-bottom: 1px solid lightgrey; 
    margin: 0px;
}

#appDates.row {
    padding-left: 0;
    padding-right: 0;
}

/* .progress-bar-wrapper {
    padding-bottom: 8%;
} */

#appDates {
    border-bottom: 1px solid lightgrey; 
    margin: 0px;
}

#dueDatesColumn {
    text-align: center!important; 
    padding-bottom: 10%; 
    padding-top: 10%
}

h4#applicationDue {
    color: #555;
    font-weight: 400;
    font-family: ubuntu;
    font-size: 18px;
    display: inline;
}

#dueDatesColumn hr {
    display: block!important; 
    width: 25%; 
    margin-top: 10px; 
    margin-bottom: 10px;
}

#dueDatesColumn .material-icons {
    color: #29a79f; 
    padding-right: 0px; 
    margin-top: 0px;
    margin-left: 5px;
    margin-right: -5px;
    float: left;
    font-size: 20px!important;
    display: none;
}

#dueDatesColumn p {
    display: inline; 
    font-weight: 600; 
    color: #555
}

#closeDateColumn {
    text-align: center!important; 
    padding-bottom: 10%; 
    padding-top: 10%
}

#closeDateColumn h4 {
    color: #555;
    font-weight: 400;
    font-family: ubuntu;
    font-size: 18px;
    display: inline;
}

#closeDateColumn hr {
    display:block!important;
    width: 25%; 
    margin-top: 10px; 
    margin-bottom: 10px;
}

#closeDateColumn.material-icons {
    color: #29a79f; 
    padding-right: 0px; 
    font-size: 20px!important;
}

#closeDateColumn p {
    display: inline; 
    font-weight: 600; 
    color: #555
}

h4#taggedUnder {
    margin-bottom: 0; 
    margin-top: 8%;
}

#taggedUnderRow {
    margin-bottom: 3%; 
    margin-top: 2%;
}

#taggedUnderRow.row {
    padding-left:0;
    padding-right: 0;
    display: flex;
}

.listingPartialBody {
    padding: 0; 
    margin-top: 0
}

#description {
    padding-left: 0;
    padding-right: 0;
}

#descriptionRow.row {
    padding-left: 0;
    padding-right: 0;
}

.listingPartialContent h4 {
    margin: 0; 
    margin-top: 0%; 
    margin-bottom: 2%
}

.listingPartialContent .col-md-12 {
    padding-left: 0;
}

h4#copyToClipboard {
    cursor: pointer;
}

h4#shareListingHeader {
    cursor: default!important;
}

.listingAgency img {
    text-align: center;
}

#additionalInfo {
    padding-left: 15px; 
    padding-right: 15px;
}

#additionalInfo h4, #shareListing h4 {
    color: #444; 
    font-size: 18px; 
    margin-top: 15%; 
    cursor: text; 
    font-family: ubuntu;
}

#additionalInfo p {
    font-size: 15px; 
    color: #666; 
    text-align: left;
}

#listingViews {
    padding-left: 15px; 
    padding-right: 15px;
}

#listingViews h4 {
    color: #444; 
    font-size: 18px; 
    margin-top: 15%; 
    cursor: text; 
    font-family: ubuntu;
}

#listingViews p {
    font-size: 15px; 
    color: #666; 
    text-align: left;
}

#shareListing {
    padding-left: 15px; 
    padding-right: 15px;
}

input#listingLink {
    margin-top: 0!important;
}

h4#copyLink {
    margin-top: 10px!important;
    font-size: 14px;
    cursor: pointer!important;
}

input#copyLinkInput {
    margin-top: 0px!important;
}

#listingMain {
    bottom: 0;
    right: 0;
    left: 300px;
    top: 10px;
    width: auto;
    height: 100%;
    position: relative;
    background: #ffffff;
    z-index: 999;
    position: absolute;
    /* padding: 5%; */
    top: 0;
}

#listingArea {
    background: #fff;
    padding: 5%!important;
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
    padding: 1rem;
    width: 90%;
    border-radius: 5px;
    border: 1px solid #c4c8d8;
    box-shadow: 0 2px 5px 3px #e3e5ec;
    min-height: 100vh;
    margin-bottom: 10%;
}

.listingTopBar {
    background: #fff;
    border-bottom: 1px solid lightgrey;
    height: 60px;
    z-index: 10000000;
    padding: 0% 1%;
}

.backArrow {
    display: flex;
    align-items: center;
    float: left;
}

.openListing h4 {
    display: flex;
    align-items: center;
    float: right;
}

.listingTopBar h4 {
    float: right;
    color: #29A79F;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 60px;
    margin: 0;
    padding-right: 2%;
}

.listingPartialDiv {
    position: relative;
    top: 0px;
    bottom: 0px;
    margin: auto;
    margin-bottom: 0px;
    background: white;
    min-width: 300px;
    width: 100%;
    right: 0px;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
}

.listingPartialBody {
    background: #F2F2F2;
    padding: 5%;
}

.listingCompleteBody {
    background: #F2F2F2;
    padding: 0;
}

.listingPartialContent {
    padding: 8% 8%;
    position: relative;
    top: 0px;
    margin: auto;
    background: white;
    box-shadow: 0 5px 5px -1px rgba(47,54,57,0.15), 0 0 5px 0 rgba(47,54,57,.15), 0 5px 10px 0 rgba(47,54,57,.0);
}

.listingPartialContent h6 {
    background: #E0DFE0;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 30px;
    display: inline-block;
    margin: 2px;
    font-size: 10px;
    color: #3e454a;
}

.listingPartialTitle h4 {
    line-height: 1.25em;
    font-family: sans-serif;
    font-weight: 300;
    font-size: 20px;
    color: #fff;
}

.listingPartialTitle {
    margin: 0;
    background: #29a79f;
    padding: 4%;
}

.listingAgency {
    text-align: center;
    padding-top: 5% !important;
}

.listingAgency h4 {
    font-size: 18px;
    margin-top: 25px;
    text-align: center;
    color: #444444;
    font-weight: 600;
    font-family: sans-serif;
    line-height: 1.35em;
}

.additionalInfo {
    text-align: center;
}

.additionalInfo button {
    padding: 0;
    min-height: 80px !important;
    font-family: ubuntu;
    font-weight: 600;
    margin-top: 5%;
    width: 100%;
    box-shadow: 0 5px 5px -1px rgba(47,54,57,0.15), 0 0 5px 0 rgba(47,54,57,.15), 0 5px 10px 0 rgba(47,54,57,.0);
    border-radius: 4px;
}

.additionalInfo .material-icons, .dueDates .material-icons {
    display: inline-block;
    line-height: 20px;
    vertical-align: middle;
    margin-right: 2px;
    padding-bottom: 0;
    border: none;
    box-shadow: none;
    color: #fff;
}

.material-icons {
    font-size: 22px;
    margin-top: -3px;
}

/* h4 {
    color: #29a79f;
    font-family: ubuntu;
    font-size: 18px;
} */

button#applyNowButton {
    background: #29a79f!important;
    margin:auto;
    width: 100%;
    margin-top: 5%;
    /* height: 50px; */
}

button#applyNowButton:hover {
    background: teal!important;
}

#listingMain h1 {
    color: #3a4570;;
    font-size: 24px;
    max-width: 650px;
    font-weight: 600;
    margin-top: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

h4#applyButton {
    font-weight: 500;
    text-align: right;
    margin-top: 0;
    margin-right: 5%;
}

i#listingShareIcon {
    margin-top: -25px;
    margin-right: -20px;
    color: #3d518b;
}

i#listingShareIcon:hover {
    cursor: pointer;
}

#listingAgencyIcon {
    float: left;
    color: #999;
    margin-top: 0px;
    margin-left: 0px;
}

h2#descriptionAgency {
    color: #777;
    font-size: 18px;
    font-weight: 400;
}

.read-more-button {
    color: #29a79f;
    margin-top: 2%;
    font-family: open sans;
    font-weight: 600;
}

.read-more-button:hover {
    cursor: pointer;
}

/* .progress-bar {
    background: none;
} */

li.section {
    font-weight: 400 !important;
    vertical-align: baseline !important;
    white-space: nowrap;
}

li.section:before {
    line-height: 26px !important;
}

/* ul.progress-bar {
    padding-bottom: 7% !important;
} */

#dangListingDescription p,
p#listingDescription {
    font-size: 16px;
    font-weight: 400;
}

p#linkCopied {
    float: right;
    margin-right: -20px;
    margin-top: 10px;
    color: #3a4570;
    font-weight: 400;
}

p.Alert {
    background: #29A79F;
    padding: 3px;
    font-size: 9px;
    color: #fff;
    display: block;
    position: absolute;
    border-radius: 3px;
    font-family: ubuntu;
    padding-left: 6px;
    padding-right: 6px;
    margin-top: -25px;
    width: 40%;
    margin-left: 25%;
    margin-right: auto;
    right: auto;
    white-space: nowrap;
}

.alert-error {
    color: #884646;
    background-color: #E9C6C6;
    border-color: #E9C6C6;
    height: 50px;
    line-height: 50px;
    padding-left: 2.5%;
    font-size: 14px;
    margin-top: -25px;
    margin-bottom: 25px;
}

.alert-success {
    color: #468847;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.settingsForm .alert-success {
    color: #468847;
    background-color: #dff0d8;
    border-color: #d6e9c6;
    height: 50px;
    line-height: 50px;
    padding-left: 2.5%;
    font-size: 14px;
    margin-top: -25px;
    margin-bottom: 25px;
}

#linkCopySuccess .alert-success {
    padding: 15px;
    border-radius: 5px;
}

#sideInfo hr {
    width: 100%;
    margin-top: 15%;
    color: #555;
    height: 1px;
    background: #e5e5e5;
}

#sideInfo input {
    background: none;
    padding: 10px;
    border: 1px solid lightgrey;
    border-radius: 1px;
    box-shadow: none;
    width: 100%;
    margin-top: 10%;
}

#sideInfo h4 {
    font-family: sans-serif;
    font-weight: 600;
    cursor: default;
    text-align: left;
}

.requestInfoButton, .findWriterButton {
    background: #fdfdfd;
    color: #1e7377;
    cursor: pointer;
}

@media only screen and (max-width: 999px) {
    .col-md-8 {
        padding: 0;
    }

    .col-md-4 {
        padding: 0;
    }
    .dueDates {
        margin-top: 5%;
    }

    .listingPartialBody {
        padding: 0 !important;
    }
    .material-icons {
        margin-top: 0!important;
    }

    #listingMain {
        top: 175px!important;
    }

    #listingArea {
        border: none;
        box-shadow: none;
    }

    #listingMain h1 {
        color: #3a4570;
        font-size: 18px;
        max-width: 650px;
        font-weight: 600;
        margin-top: 25px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        margin-right: 35px;
    }

    i#listingShareIcon {
        margin-right: -10px!important;
    }

    p#linkCopied {
        margin-top: 25px;
    }

    #listingButtons button {
        width: 100%!important;
    }
}