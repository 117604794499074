.redirectLinks {
    margin-top: 30px;
}
.redirectLinks > a {
    display: block;
    font-size: 20px;
}

#pageNotFoundDiv {
    max-width: 700px; 
    height: 100vh;
    text-align: center; 
    margin: auto; 
    margin-top: 10%;
    box-shadow: 0 1px 5px -1px rgba(47,54,57,.07), 0 0 5px 0 rgba(47,54,57,.06), 0 1px 10px 0 rgba(47,54,57,.05);
    font-size: 14px;
    display: inline-block;
    background: white;
    padding: 15px;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 95%;
}

h1#oopsHeader {
    font-weight: 900;
    color:#29A79F;
}

h2#pageMissingHeader {
    font-size: 20px;
    font-family: raleway;
}

img#pageNotFoundBackground {
    vertical-align: middle;
    position: absolute;
    margin-bottom: 100%;
    width: calc(100% - 300px);
    height: 100vh;
    top: 0px;
    bottom: 0;
    left: 300px;
    right: 300px;
}

img#outsidePageNotFoundBackground {
    vertical-align: middle;
    position: absolute;
    margin-bottom: 100%;
    width: 100%;
    height: 100vh;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
}

hr.pageNotFoundHR {
    width: 10%;
    border-bottom: 2px solid #999;
}
