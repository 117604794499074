h1#forgotPasswordLogo {
    font-family: Ubuntu; 
    color: #29A79F; 
    font-size: 36px; 
    text-align: center; 
    font-weight: 700; 
    margin-bottom: 40px;
}
#iHateGoogle {
    height: 0;
    position: absolute;
    overflow: hidden;
}

#forgotPassword input.form-control {
    height: 50px!important;
}

#forgotPassword label {
    text-align: left;
}

.forgotPassword hr, .forgotPasswordConfirmation hr {
    border: 1px solid #e6e5e5; 
    margin-bottom: 25px;
}

#forgotPasswordIssues {
    margin-top: 40px;
}

#forgotPasswordIssues h3 {
    font-size: 16px; 
    padding-bottom: 10px; 
    font-weight: 300; 
    margin-bottom: 0;
}

#forgotPasswordIssues a {
    color: teal;
}

input#forgotPasswordEmailInput {
    border: 1px solid #2A88F4;
    margin-right: 10px;
    border-radius: 5px;
    padding: 5px 10px;
    min-width: 250px;
}

button#forgotPasswordSubmit {
    margin-top: 10px; 
    padding: 5px 30px; 
    border-radius: 5px; 
    color: white; 
    background:#29A79F; 
    border: 0; 
    font-size: 15px;
}

.forgotPasswordBottomLinks {
    position: absolute;
    bottom: 5%;
    left: 0;
    font-size: 14px;
}

.resetPasswordBottomLinks {
    position: relative;
    bottom: 5%;
    left: 0;
    font-size: 14px;
}

#forgotPassowrdRightLogin {
    max-width: 650px;
    /* background: #fff; */
    padding: 5%;
    color: #555;
    text-align: center;
    margin: auto;
}

#forgotPasswordRedirect {
    margin: 20px;
}

h2#forgotPasswordRedirectHeader {
    font-size: 18px; 
    padding-bottom: 10px; 
    font-weight: 300; 
    margin-bottom: 0;
}

h1#passwordResetLinkHeader {
    font-size: 22px; 
    margin-bottom: 25px;
}

span#redirectForgotPasswordConfirmation {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}

.forgotPasswordLinks, .resetPasswordLinks {
    margin-bottom: 10px;
}

.forgotPasswordLinks > a, .resetPasswordLinks > a {
    margin-right: 15px;
    color: #2AA79F;
    cursor: pointer;
    font-family: open sans;
    font-weight: 600;

}

.forgotPasswordLinks > a:hover, .resetPasswordLinks > a:hover {
    text-decoration: none;
}

.forgotPassword button {
    background: #29A79F;
    color: white;
    border-radius: 5px;
    border: 0;
    padding: 5px 10%;
}
.forgotPasswordLinks, .resetPasswordLinks, .forgotPasswordCopyFooter, .resetPasswordCopyFooter {
    padding-left: 5%;
    display: inline-block;
}

.resetPasswordCopyFooter {
    display: block!important;
    margin-bottom: 2%!important;
}

.forgotPassword input[name="Email"] {
    width:90%;
    margin-right: 0!important;
}

form#changePasswordForm .ReactPasswordStrength-input {
    border: 1px solid lightgrey;
    border-radius: 5px;
}

@media only screen and (max-width: 1248px) {
    #rightLogin {
        padding: 0;
    }
}

@media only screen and (max-width: 999px) {
    #leftLogin {
        display: none;
    }

    .forgotPasswordLinks > a {
        display: block;
    }

    h1#passwordResetLinkHeader {
        font-size: 2em!important;
        margin-top: 0;
    }
}

@media only screen and (max-width: 500px) {
    .resetPasswordBottomLinks {
        margin-bottom: 10%;
    }

    .forgotPasswordLinks > a, .resetPasswordLinks > a {
        display: block;
        margin-top: 2%;
        margin-bottom: 2%;
    }
}