.entityLabel {
    position: absolute;
    display: inline-block;
    line-height: 1.5em!important;
    margin-top: 8px!important;
    max-width: 400px;
}

.entityLabelBox {
    margin-bottom: 50px;
}

.progress {
    height: 15px;
    position: absolute;
    width: calc(100% - 300px);
    top: 0;
    left: 300px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: #fafaf7;
    border-radius: 0;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 11px;
    line-height: 15px;
    color: #fff;
    border-radius: 0px;
    text-align: center;
    background-color: #2ca79e;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
}

#NSF_Category_Selector .MuiFormControl-root {
    border: none!important;
    background: none!important;
}

a#howItWorks_Signup {
    cursor: pointer;
    text-decoration: none;
    color: #444;
    position: absolute;
    right: 10%;
    top: 25px;
}

h1#howItWorksHeader {
    max-width: 600px; 
    margin: auto;
    display: block;
    text-align: center;
    margin-top: 10%;
    font-size: 50px;
    color: #444;
    font-family: open sans;
    font-weight: 900;
}

h1#howItWorksMidHeader {
    text-align: center;
}

h2#creatingAccountLoader {
    top: 35%;
    position: relative;
    margin: auto;
    text-align: center;
    font-family: ubuntu;
    font-weight: 700;
    color: #555;
    font-style: italic;
}

.css-0 {
    display: block!important;
    margin: auto!important;
    margin-top: 40vh!important;
    width: 165px!important;
}

.MuiInputBase-input {
    font-size: 14px!important;
}

#zipInput .MuiFormControl-root.MuiTextField-root {
    margin-bottom: 5%;
    margin-top: 0!important;
    max-width: 350;
    font-size: 34;
}

#fundingSlider1,
#fundingSlider2,
#fundingSlider3,
#fundingSlider4,
#fundingSlider5,
#fundingSlider6,
#fundingSlider7,
#fundingSlider8,
#fundingSlider9,
#fundingSlider10 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 2em;
    padding: 10%;
    padding-bottom: 0%;
    max-width: 1150px;
    margin: auto;
    padding-top: 5%;
    margin-top: 5%;
}
/* 
#fundingSlider1 {
    margin-top: 0!important;
} */

#fundingSlider2, 
#fundingSlider3, 
#fundingSlider5,
#fundingSlider6,
#fundingSlider8 {
    margin-top: 10em!important;
}

#fundingSlider4 {
    max-width: 1000px;
}

#fundingSlider9 #zipInput .MuiFormControl-root.MuiTextField-root {
    margin-bottom: 0px!important;
}

#fundingSlider9 .MuiInputLabel-root {
    margin-left: 10px;
    /* margin-top: -5px; */
}

#fundingSlider9 .MuiInputBase-input {
    font-size: 14px!important;
    margin-left: 10px!important;
}

h1#fundingSliderHeader {
    max-width: 750px;
    font-size: 40px;
    font-family: ubuntu;
    font-weight: 500;
    color: #444;
    margin-bottom: 8%;
    margin: auto;
    text-align: center;
}

output#output {
    font-size: 36px;
    font-family: Raleway;
}

button.signUpContinue {
    background: #2aa79f;
    margin: auto;
    color: #fff;
    height: 80px;
    width: 200px;
    font-size: 20px;
    font-family: raleway;
    border-radius: 2px;
}

.continueButton {
    margin-top: 5%;
    text-align: center;
    margin-bottom: 10vh;
}

button.yesNoButton {
    background: #2aa79f;
    margin: auto;
    margin-left: 1%;
    margin-right: 1%;
    color: #fff;
    height: 80px;
    width: 200px;
    font-size: 20px;
    font-family: raleway;
    border-radius: 2px;
    border: none;
}

hr#signUpSliderHR {
    margin-top: 0;
    margin-bottom: 10px;
    width: 10%;
    border-top: 3px solid #e0dfdf;
}

h1#signUpWelcomeH1 {
    text-align: center;
    color: #2aa79f;
    font-size: 42px;
    margin: auto;
    margin-top: 10%;
    width: 100%;
    margin-bottom: 5%;
    font-family: ubuntu;
    font-size: 32px;
    font-weight: 600;
}

h1#askQuestions {
    font-size: 32px;
    text-align: center;
    color: #444;
    font-weight: 400;
    margin: auto;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 5%;
    font-family: ubuntu;
    letter-spacing: 0.025em;
    line-height: 1.25em;
}

#sliderTerms {
    text-align: center;
    font-size: 14px;
    max-width: 500px;
    margin: auto;
    margin-top: 10%;
}

.MuiTypography-body1 {
    font-size: 1.5rem!important;
    font-family: open sans!important;
    line-height: 2!important;
}

#NSF_Category_Selector .MuiSvgIcon-root {
    /* fill: #29A79F!important; */
    width: 2em!important;
    height: 2em!important;
    font-size: 2rem!important;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

#NSF_Category_Selector .MuiFormControl-root {
    margin-top: 0!important;
    display: block;
}

#NSF_Category_Selector .MuiFormControlLabel-root {
    display: block;
}

#focusCheckLeft, #focusCheckRight {
    display: inline-grid;
}

#NSFFocusContinueButton.continueButton,
#SBIRFocusContinueButton.continueButton {
    margin-top: 15%;
    margin-bottom: 25%;
}

#BusinessLocationContinueButton.continueButton {
    margin-top: 10%;
    margin-bottom: 25%;
}


h3#checkAllH3 {
    color: #666;
    font-family: raleway;
    font-size: 18px;
    margin-bottom: -10px;
}

#focusNSFArea {
    max-width: 475px;
    margin-bottom: 5%;
    margin: auto;
}

h3#focusHeader {
    font-family: raleway;
    font-size: 20px;
    color: #29A79F;
}

.MuiFormControl-root {
    width: 100%;
    margin-top: 5%!important;
}

.MuiFormLabel-root {
    font-size: 13px!important;
}

.MuiInputLabel-formControl {
    transform: translate(0, 18px) scale(1);
}

#rightSignUp {
    padding: 5%;
    height: 100%; 
    background-color: white;
}

h1#signUpTagLine {
    text-align: center;
    color: #333;
    line-height: 1.25em;
    font-family: Montserrat;
    max-width: 750px;
    margin: auto;
    font-weight: 900;
}

#signUpPage textarea {
    height: 200px;
}

#signUpPage h2 {
    margin-bottom: 50px;
    font-size: 16px;
}

@media only screen and (max-width: 1000px) {
    h1.mobileRegisterlogo {
        font-size: 32px;
        text-align: center;
        color: #29A79F;
        font-family: ubuntu;
        line-height: 50px;
    }

    h1.regHeader {
        font-size: 24px;
        color: #888;
    }
}

@media only screen and (max-width: 992px)
{
    #rightSignUp {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) 
{
    #fundingSlider2, 
    #fundingSlider3, 
    #fundingSlider5,
    #fundingSlider6,
    #fundingSlider8 {
        margin-top: 0em!important;
    }
    .nextButton, .backButton {
        width: 49%;
        padding: 5%!important;
        border-radius: 5px!important;
        margin-top: 15px;
    }

    h1#fundingSliderHeader, 
    h1#howItWorksHeader,
    h1#signUpWelcomeH1 {
        margin-top: 0%;
        margin-bottom: 15%;
        font-size: 32px;
    }

    #fundingSlider5, #fundingSlider6, #fundingSlider8 {
        margin-top: 5em!important;
    }

    #fundSliderDiv {
        margin-top: 35px;
    }

    output#output {
        margin-bottom: 35px;
    }

    button.yesNoButton {
        width: 40%;
        margin: 10px;
    }

    p#regHelperText {
        margin-left: 25px;
        margin-right: 25px;
    }

    button.signUpContinue {
        margin-top: 35px;
    }

    #zipInput {
        min-width: 90%;
    }

    #focusNSFArea {
        margin: 5%;
    }

    #NSF_Category_Selector .MuiFormControlLabel-root {
        display: flex;
    }

    #signUpLinkWrite {
        display: none!important;
    }

    h1#askQuestions {
        font-size: 2em;
    }

    #zipInput {
        min-width: 250px;
        max-width: 90vw;
    }

    .progress {
        height: 20px;
        position: absolute;
        width: 100%;
        top: 165px;
        left: 0;
        margin-bottom: 20px;
        overflow: hidden;
        background-color: #f5f5f5;
        border-radius: 0;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    }
}

@media only screen and (max-width: 500px)
{
    #fundingSlider1, #fundingSlider2, #fundingSlider3, #fundingSlider4, #fundingSlider5, #fundingSlider6, #fundingSlider7, #fundingSlider8, #fundingSlider9, #fundingSlider10 {
        padding: 5%;
    }

    #fundingSlider4 {
        width: 85vw!important;
    }

    .awssld__controls {
        visibility: inherit;
    }

    .awssld__controls button {
        margin-right: 5px;
    }
}
