/* MarketplaceSearch */
#TalentFinderHeader {
    font-family: ubuntu;
    color: black;
    font-size: 30px;
    margin-top: -25px;
    font-weight: 600;
}
.n-padding {
    padding-left: 0px;
}
.ais-Stats-text {
    font-size: 20px !important;
    font-family: ubuntu;
    color: #1A5A83 !important;
}
#searchListingsSearchBar .ais-Hits-item {
    box-shadow: none !important;
    border: none !important;
    padding: 0px !important;
}
.aboutTalentFinderContaitner {
    margin-left: 120px;
    margin-top: -50px;
    padding-right: 10px;
}


/* Hit */
.ais-Hits-item img {
    float: left !important;
    border-radius: 50% !important;
    overflow: hidden !important;
    object-fit: cover !important;
}
.TaletFinderTitle {
    font-family: ubuntu;
    color: black;
    font-size: 18px;
    font-weight: bold;
}
.MuiButton-label{
    font-weight: bold;
    font-size: 14px;
}

@media only screen and (max-width: 500px) {
    .hitViewProfileBtn {
        float: left !important;
        width: 100% !important;
        flex-direction: column !important;
    }
    .MuiButton-label {
        justify-content: flex-start !important;
    }
}

/* Smilte's styling */
.spContainer{
    display: flex;
}

.spImageDiv{
    padding: 0;
}

.spLogo{
    width: clamp(65px, 7vw, 85px);
    height: clamp(65px, 7vw, 85px);
    margin-right: 10px;
    margin-left: 10px;
}

.spInfoDiv{
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-top: 0.5vw;
    width: 100%;
}


.spAboutDiv{
    margin-left: 20px;
}

.hit-title{
    color: black !important;
    width: 100% !important;
    padding-right: 10px;
}

.hit-header{
    display: flex;
    justify-content: space-between
}

.focusAreasTags,
.servicesTags {
    padding: 0;
    width: 50%;
}

.spTags{
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 750px) {
    .spTags{
        flex-direction: column;
    }
    .servicesTags{
        margin-top: 10px;
    }
}

@media only screen and (max-width: 750px) {
    .spContainer{
        flex-direction: column;
    }
    .hit-header{
        flex-direction: column;
        justify-content: center;
    }
    .spImageDiv{
    
        display: flex;
        justify-content: center;
    }
    .hit-title{
        padding-right: 0;
        text-align: center;
    }
}