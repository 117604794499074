.sweet-loading {
    position: fixed;
    margin: auto;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #dde5e894;
    z-index: 99;
}

.css-vlatow, .css-amacvd {
    background-color: #29a79f!important;
}

.pagination {
    margin: 0!important;
}

p#resultsReturned {
    margin-bottom: 5px!important;
}

.pagination>li>a, .pagination>li>span {
    margin-right: 5px!important;
    border-radius: 5px!important;
}