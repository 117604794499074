.mainLandingLogo {
    cursor: pointer;
    text-align: center;
    font-family: Ubuntu;
    color: #29A79F;
    float: left;
    margin: auto;
    line-height: 90px;
    position: absolute;
    top: 0;
    left: 35px;
    font-size: 38px;
}

#landing-navbar-collapse {
    margin-top:0; height: 0px;
}

#desktop {
    float:right; line-height: 80px;
}

#desktop > nav {
    float: right; 
    margin-right: 25px;
}
 
.login {
    border: 2px solid #2aa79f  !important; 
    color: #2aa79f  !important;
}

.sign-up {
    background: #2aa79f  !important; 
    color: white  !important; 
    border: 1px solid #2aa79f  !important;
}

#mobile {
    border-color: #101010;
    background: #252f33;
    float: left;
    width: 101%;
    margin: 0;
    line-height: 40px;
}

#mobile > ul {
    padding: 0; 
    margin: 0;
}

#mobile > ul > li {
    display: block;
    padding-left: 5%;
    line-height: 100px;
    border-bottom: 1px solid #c0c0c0;
}

#mobile a {
    color: #fff;
}

.mobile-sign-up {
    border-bottom: 1px solid #333;
}

#mintBackground {
    background-image: url(https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/startup_funding_ui.jpg);
    clear: both;
    text-align: center;
    padding: 7%;
    background-size: cover;
    background-position-y: bottom;
    margin-top: -20px;
}

#mintBackground h1 {
    font-family: Ubuntu;
    font-size: 7vh;
    padding-bottom: 10%;
}

#mintBackground > :nth-child(2) {
    padding-top: 50px;
    padding-bottom: 100px;
}

.heading {
    margin-top: 8%; 
    margin-bottom: 10%;
}

.heading > p {
    color: #7D7D7D; 
    font-size: 20px; 
    font-family: open sans;
}

.item {
    margin-top: 75px!important; 
    margin-bottom: 75px!important; 
    margin:auto; 
    padding: 0 3%;
}

.footing {
    text-align: center; 
    padding-top: 8%; 
    padding-left:10%; 
    padding-right: 10%;
}

.footing > div {
    max-width: 600px; 
    margin: auto; 
    margin-bottom: 50px;
}

.partial-hr {
    width: 75%;
}

.register_client_button {
    display:inline-block;
    background:#29A79F;
    color:white;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 16px;
    margin-bottom:75px;
}

#fundingBackground > h1 {
    display: inline-block; 
    color: white;
    font-family: Oswald; 
    font-size: 50px; 
    font-weight: 700;
}

#fundingBackground > :nth-child(2) {
    font-size: 60px; 
}

.center-text {
    text-align: center;
}

.register_contractor_button {
    display:inline-block;
    background:#29A79F;
    color:white;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 16px; 
    margin-bottom: 25px;
}

* {
    -webkit-overflow-scrolling: touch;
}

#navbarMain {
    display: none;
}

a {
    cursor: pointer;
    text-decoration: none;
    color: black;
}

/* footer h3, footer h4, footer a {
    color: #fff;
    font-family: open sans;
}

footer h3 {
    font-size: 18px;
    margin-top: 0;
}

footer a:hover {
    color: #2AA79F;
}

body {
    font-family: Open Sans;

} */

#desktop a {
    font-size: 13px;
    font-weight: bold;
    margin-right: 30px;
}

.button {
    border-radius: 20px;
    font-size: 14px;
    font-weight: normal;
    background: white;
    border: 1px solid #E1E1E1;
    padding: 5px 25px;
    margin-right: 10px;
}

/* hr {
    border: 0;
    border-top: 1px solid #e0e0e0;
    width: 15%;
} */

.navbar {
    /* display: none; */
}

.grantOptions {
    margin-bottom: 50px;
}

#getStarted {
    color: white;
    background: #2aa79f;
    border: none;
    padding: 14px 25px;
    font-weight: bold;
    margin-right: 0px;
    border-radius: 25px;
}

#landingFeatures {
    padding: 5% 10%;
    text-align: center;
}

#landingFeatures img {
    height: 75px;
}

#landingFeatures h1 {
    font-family: ubuntu;
    color: #306571;
    font-size: 28px;
}

#comingSoon {
    display: block;
    margin: 15px auto 0 auto;
    background: #29A79F;
    color: white;
    border-radius: 5px;
    padding: 2px 20px;
    margin-top: -28px;
    margin-bottom: 10px;
    font-size: 10px;
    max-width: 120px;
}

/* label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
    font-family: open sans;
    font-weight: 400;
    color: #555;
} */

.grantOptions h1 {
    font-size: 22px;
    font-weight: normal;
}

h3.benefits {
    color: #1d4354;
    font-size: 34px;
    font-weight: bold;
    letter-spacing: 3px;
}

.grantOptions label {
    font-size: 12px;
}

#fundingBackground {
    background: #1d4354;
    padding: 10%;
    text-align: center;
}

.grantOptions img {
    max-width: 129px;
    height: 129px;
}

h1#solution {
    color: #2aa79f;
    font-size: 40px;
    letter-spacing: 2px;
    font-weight: 700;
    font-family: ubuntu;
}

.endtoend p {
    font-size: 16px;
    font-family: open sans;
    max-width: 500px;
    margin: auto;
    color: #555;
}

.endtoend h3 {
    color: #2aa79f;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    display: inline-block;
}

/* button.navbar-toggle {
    line-height: 80px;
    background: #fff;
    border-color: #2aa79f;
    height: 50px;
    width: 50px;
    margin-top: 15px;
    margin-right: 25px;
} */

.navbar-inverse .navbar-toggle .icon-bar {
    background-color: #145067;
    margin-right: 0;
    width: 100%;
    color: #2aa79f;
}

.navbar-inverse .navbar-toggle {
    border: 2px solid #d4e2e1;
}

.navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
    background-color: #fff;
}

@media only screen and (max-width: 1000px) {
    .navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
        padding: 0;
        margin-top: 5px;
        width: 105%;
        position: absolute;
        left: 0px;
        z-index: 4;
    }

    .logo {
        left: 5%;
        font-size: 30px;
    }

    .main-body {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    #desktop {
        display: none;
    }

    #mobile {
        display: block;
        width: 100%;
    }

    #mobile li {
        padding-left: 10%;
        font-size: 20px;
    }

    .navbar-inverse {
        position: inherit;
        width: 100%;
        box-shadow: none;
        border: none;
    }

    .navbar-inverse .navbar-toggle {
        margin-right: 25px;
        margin-top: 20px;
    }

    .clear-mobile-landing {
        line-height: 0px;
        font-size: 32px;
        color: #145067;
        font-weight: 600;
        font-family: ubuntu;
    }
}

@media only screen and (max-width: 500px) {
    .mainLandingLogo {
        left: 15px;
        font-size: 10vw;
    }
}