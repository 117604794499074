.matchedListing {
    margin-bottom: 1em;
    width: calc(33.3% - 1rem)!important;
    padding: 2rem!important;
    background: #fdfdfd;
    margin-top: 1rem;
    margin-left: 1rem;
    padding: 1rem;
    /* width: 100%; */
    border: 1px solid #c4c8d8;
    box-shadow: 0 2px 5px 0px #e3e5ec;
}

.matchBody {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}

#matchResults .ais-Hits-item {
    width: calc(33.3% - 1rem)!important;
}

#matchResults p#fundingAmount {
    display: contents
}

.matchTitleAgency {
    height: 7.5em;
}

.matchBody .hit-title {
    font-size: 18px;
    font-family: open sans;
    font-weight: 600;
    max-width: 700px;
    width: 100%;
}

.matchBody .hit-agency {
    color: #888;
    font-size: 16px;
    margin-bottom: .5em;
    width: 100%;
}

.matchBody .hit-funding {
    color: #666;
    font-family: ubuntu;
    font-weight: 600;
    font-size: 15px;
    line-height: 25px;
    width: 100%;
}

.matchBody .hitActions {
    position: relative;
    bottom: 0px;
    display: inline-block;
    align-self: flex-end;
    width: 100%;
}

.matchBody hr {
    width: 100%;
}

.clearMatchDiv,
.saveMatchDiv,
.shareResultDiv {
    /* border: 1px solid lightgrey; */
    width: 35px;
    padding: 8.25px;
    padding-left: 9px;
    border-radius: 2px;
    height: 35px;
    background-color: #fff;
    background-image: linear-gradient(-180deg,#fff,#fcfcfd);
    border: 1px solid #d6d6e7;
    box-shadow: 0 1px 0 0 rgba(35,38,59,.05);
    float: right;
    margin-left: 7.5px;
    margin-top: 7.5px;
    color: #363f61bf;
}

.shareResultDiv {
    padding-left: 8px!important;
}

.clearMatchDiv {
    padding-top: 9px;
}

.saveMatchDiv {
    padding: 8px!important;
    padding-left: 7.5px!important;
}

.shareResultDiv:hover, 
.saveMatchDiv:hover,
.clearMatchDiv:hover {
    cursor: pointer;
    background: #f6f6f6;
}

#noNewRecommendations {
    padding: 5%;
    background: #fff;
    text-align: center;
    box-shadow: 0 1px 5px -1px rgba(47,54,57,.07), 0 0 5px 0 rgba(47,54,57,.06), 0 1px 10px 0 rgba(47,54,57,.05);
}

h3.noNewRecommendationsText {
    color: #555;
    font-family: raleway;
}

p.noNewRecommendationsText {
    font-size: 16px;
    max-width: 650px;
    text-align: center;
    margin: auto;
    font-weight: 400;
}

button.noNewRecommendationsButton {
    padding: 10px;
    border-radius: 3px;
    background: #456270;
    color: #fff;
    box-shadow: 0 1px 6px rgba(57,73,76,0.35);
    font-family: open sans;
    font-weight: 600;
    margin: 10px;
    width: 30%;
}

#noNewRecommendations h4 {
    color: #666;
    font-family: ubuntu;
    font-size: 32px;
}

#recommendedOpportunitiesDashboard h1 {
    font-size: 36px;
    font-family: ubuntu;
    color: #666;
    margin-top: -36px;
}

i#addMatchButton:hover, i#discardMatchButton:hover {
    cursor: pointer
}

.matchedResultColumn {
    width: calc(33.3% - 1rem);
}



@media only screen and (max-width: 1350px) {
    #matchResults .ais-Hits-item {
        width: calc(50% - 1rem)!important;
    }
}

@media only screen and (max-width: 999px) {
    #matchResults .ais-Hits-item {
        width: 100%!important;
    }

    .clearMatchDiv,
    .saveMatchDiv,
    .shareResultDiv {
        /* border: 1px solid lightgrey; */
        width: 35px;
        padding: 8.25px;
        padding-left: 9px;
        border-radius: 2px;
        height: 35px;
        background-color: #fff;
        background-image: linear-gradient(-180deg,#fff,#fcfcfd);
        border: 1px solid #d6d6e7;
        box-shadow: 0 1px 0 0 rgba(35,38,59,.05);
        float: right;
        margin-left: 7.5px;
        margin-top: 7.5px;
        color: #363f61bf;
    }

    /* .shareResultDiv {
        padding-left: 8px!important;
    }

    .clearMatchDiv {
        padding-top: 9px;
    } */

    .saveMatchDiv {
        padding: 8px!important;
        padding-left: 7.5px!important;
        padding-top: 5px!important;
        margin-left: 15px;
    }
}