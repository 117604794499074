#profileUpdateSuccess, #profileUpdateFailure {
    text-align: center; 
    margin: auto;
}

#image_error {
    display:block;
    padding-bottom: 10px;
}

#dragDropArea {
    display: inline-block; 
    cursor: pointer;
}

#dragDropArea p {
    width: 150px;
    height: 150px;
    background: #eeeeee;
    border-radius: 50%;
}

#filesUploaded {
    display: none; 
    cursor: pointer;
}

#clientImage {
    height: 150px; 
    width: 150px;
}

input#changePhotoButton {
    border: 0;
    margin: 0;
    padding: 5px 40px;
    border-radius: 15px;
    background: white;
    box-shadow: 0 0 20px -10px;
    position: absolute;
    top: 25%;
    transform: translate(25%, 50%); 
    width: auto;
}

input#filesUpload {
    display: none;
}

#saveProfileDiv {
    margin-top: 100px;
}

button#saveProfile {
    background: #29A79F;
}

#profileImage {
    position: relative;
}

p#dropzoneArea{
    position: relative;
    width: 200px;
    height: 200px;
    border-width: 2px;
    border-color: rgb(102, 102, 102);
    border-style: dashed;
    border-radius: 5px;
}
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
  z-index: 3;
  color: #2a96a7!important;
  background: none!important;
  background-color: #fff!important;
  cursor: pointer;
  border-bottom: 2px solid #2a96a7!important;
  border-radius: 0!important;
}

.profile_list {
  overflow: auto;
  background: white;
  padding: 20px;
  box-shadow: 0px 0px 10px 0px #00000011;
  margin: 10px;
  margin-bottom: 30px;
  border: 1px solid #c4c8d8;
}

.organizationList:hover {
  /* background-color: #eee; */
  text-decoration: underline;
  color: black;
}