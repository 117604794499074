* {
    -webkit-overflow-scrolling: touch;
}

:focus {
    outline: none;
}

html {
    overflow-x: hidden;
}

.row {
    margin-left: 0;
    margin-right: 0;
}

p, span, .hit-agency {
    font-weight: 500;
}

#whitelabel_Logo {
    width: 150px;
    z-index: 1;
    position: absolute;
    right: 25px;
    top: 25px;
}

.css-1rz62re {
    background: transparent !important;
    width: 75px;
    height: 75px;
    border-radius: 100%;
    border: 2px solid;
    border-color: #123abc;
    border-bottom-color: transparent;
    display: inline-block;
    -webkit-animation: animation-s8tf20 0.75s 0s infinite linear;
    animation: animation-s8tf20 0.75s 0s infinite linear;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: block;
    margin: auto;
    top: 45%;
    left: 46.5%!important;
    position: absolute;
}

#mainLayoutDiv {
    padding: 5%;
    z-index: 9;
    background: #fafafa;
    margin-top: -100px;
    margin-left: 300px;
}

.badge {
    display: none !important;
}

h4 #footerEmail {
    display: block;
    margin-top: 25px;
    margin-bottom: 25px;
    font-weight: bold;
}

.tooltip {
    display: none !important;
}

html {
    height: 100%;
}

.sidenav {
    width: 0px;
    height: 100%;
    background: #06756c;
    position: fixed;
    z-index: 100;
    display: none;
}

/* .main-body {
    margin-left: 0px;
    padding-left: 10%;
    padding-right: 10%;
} */

body {
    padding-top: 0px;
    padding-bottom: 0px;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fafafa;;
    overflow-y: auto;
    overflow-x: hidden;
}

.body-content {
    position: absolute;
    padding-left: 0;
    padding-right: 4%;
}

a:hover {
    text-decoration: none;
}

a:hover, a:focus {
    color: #ccc;
}

/* #overLay {
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.7);
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
} */

#book.material-icons, #folder.material-icons, #star.material-icons {
    box-shadow: none !important;
    font-size: 26px;
    color: #6ec35e;
    line-height: 32px;
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    font-family: sans-serif;
    font-weight: 300;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.easy-autocomplete {
    width: 100%;
    margin-right: -40px;
}

.easy-autocomplete-container {
    background: #fff;
    margin-top: 15px;
    border-radius: 5px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    font-weight: 600;
    font-size: 12px;
}

.easy-autocomplete-container ul {
    border: 0px solid rgba(94, 165, 77, .6);
    list-style: none;
    padding: 0;
    overflow-y: scroll;
    max-height: 500px;
}

.easy-autocomplete-container ul li {
    padding: 4%;
    color: #333;
    border: 1px solid lightgrey;
    margin-top: -1px;
}

.material-icons {
    display: inline-block;
    line-height: 55px;
    vertical-align: middle;
    /* margin-top: -10px; */
    margin-right: 10px;
    padding-bottom: 4px;
    border: none;
    box-shadow: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

#headerIcons a {
    text-decoration: none;
}

.container {
    width: 100%;
    height: 100%;
    padding: 0;
    /* padding-bottom: 0; */
}

.container.body-content {
    padding-right: 0;
    padding-left: 0;
}

.badge {
    display: inline-block;
    min-width: 6px;
    font-size: 15px;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background: #6ec35e;
    border-radius: 5px;
    margin-top: 0px;
    margin-left: -15px;
    height: 20px;
}

.bottom-links {
    width: 100% !important;
}

@media only screen and (max-width: 1200px) and (min-width: 1001px) {
    #mainLayoutDiv {
        padding: 10vh 5%;
    }
}

@media only screen and (max-width: 1000px) {

    .main-body {
        margin-top: 80px;
        padding-left: 5%;
        padding-right: 5%;
    }

    .listingPartialDiv {
        width: 100% !important;
        margin-top: -5px !important;
        top: -5px!important;
    }

    .listingPartialBody {
        padding: 0 !important;
        padding-top: 3% !important;
    }

    .listingTopBar h4 {
        display: none !important;
    }

    /* .progress-bar-wrapper li.section::before {
        z-index: 0;
    }

    .progress-bar-wrapper li.section {
        font-size: 12px;
        white-space: normal;
        word-spacing: 100vw;
    } */

    .listingPartialContent {
        margin-bottom: 5% !important;
    }

    .additionalInfo button {
        min-height: 60px !important;
        height: 0;
    }

    .subcontainer {
        padding-top: 0 !important;
    }

    #mainLayoutDiv {
        /* padding: 0; */
        margin-left: 0;
        margin-top: 10%;
    }

    #listingMain {
        left: 0!important;
        /* margin-top: 0; */
    }

    button.navbar-toggle {
        line-height: 80px;
        height: 50px;
        width: 50px;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 0!important;
        border: none;
        background: none!important;
        right: 25px;
    }

    .logo {
        top: 20px!important;
    }

    .navbar {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 999px) {
    #navLogo {
        left: 5%!important;
    }

    #whitelabel_Logo {
        width: 150px;
        z-index: 1;
        position: absolute;
        left: 25px;
        top: 15px;
    }

    .__react_component_tooltip.show {
        display: none!important;
    }

    form#loginForm {
        padding-top: 15vh;
        padding-bottom: 15vh;
        padding-left: 5%;
        padding-right: 5%;
    }

    #desktop-nav {
        display: none;
    }
}
