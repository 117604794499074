/* #preferenceBody {
    margin: auto;
    margin-bottom: 40px;
    background: white;
    min-width: 300px;
}

#preferenceBody_left {
    display: inline-block;
    width: 100%;
    border-right: none;
    margin-right: 10px;
}

#preference_error {
    color: red;
    margin-bottom: 10px;
}

#save_preferences {
    margin-top: 50px;
}

#save_preferences button {
    background: #1d4354d1;
}

#preferenceBody_right {
    display: inline-block; 
    width: 30%; 
    vertical-align: top;
}

#clearAll_categories {
    text-align: center; 
    margin-top: 40px;
}

input#preference_clearAll {
    background: none; 
    color: black; 
    text-decoration: underline; 
    border: 0px;
}

input.selectedCategory, input.unselectedCategory {
    display: inline-block;
    width: auto;
    padding: 10px 15px;
    margin: 5px 0px;
    border-radius: 20px;
    background: #e4e2e2;
    color: black;
    border: none;
    font-size: 14px;
    height: auto;
}

.categoryOption {
    display: inline-block;
    margin-right: 10px;
}

input.selectedCategory {
    background: #29A79F;
    color: white;
}

input.selectedCategory:hover {
    background: teal;
}

input.unselectedCategory:hover {
    background: #c5c5c2;
}

#preferenceBody input {
    height: 50px;
    border-radius: 5px;
    padding: 15px;
    width: 100%;
    max-width: 500px;
    font-size: 14px;
    border-color: #44807d4f;
    border-width: thin;
}

.react-autosuggest__suggestions-list {
    border: 1px solid #298aa769!important;
    margin-top: -2px!important;
} */

.react-autosuggest__container {
    position: relative;
  }
  
  /* .react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
  } */

  .react-autosuggest__input {
    width: 350px;
    height: 40px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaaaaaa6;
    border-radius: 2px;
}
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 350px;
    border: 1px solid #a1a5a4ad;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    max-height: 400px!important;
    overflow-y: scroll;
    margin-top: -12px;
}
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  /* .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  } */

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
    font-size: 12px;
    border-top: 0.5px solid #efeeeed1;
}
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
  
  .react-autosuggest__section-container {
    border-top: 1px dashed #ccc;
    height: auto;
    display: block;
  }
  
  .react-autosuggest__section-container--first {
    border-top: 0;
  }
  
  .react-autosuggest__section-title {
      padding: 13px 0 12px 10px;
      font-size: 13px;
      color: #777;
  }

  .question_section ul {
    position: inherit;
    z-index: 100;
    width: 100%;
    /* max-height: 325px; */
    /* max-width: 400px; */
    /* overflow-y: scroll; */
    display: block;
  }