p#mostPopular {
    position: absolute;
    height: 35px;
    background: #2aa79ed9;
    width: 150px;
    margin-left: 25px;
    margin-top: 25px;
    color: #fff;
    padding: 10px;
    padding-top: 7px;
    font-weight: 600;
    font-size: 14px;
    border-radius: 0;
    margin-top: -45px;
}

h1#getStartedProducts {
    margin-left: 0;
    margin-bottom: 3%;
    margin-top: -7%;
    font-family: ubuntu;
    color: #47506f;
    font-size: 36px;
    margin-top: 0;
    line-height: 1.15em;
}

img#grantHeroIMG {
    height: auto;
    width: auto;
    max-width: 100%;
    margin: 0;
    margin: auto;
    /* margin-left: -25px; */
    margin-top: 20%;
    display: block;
}