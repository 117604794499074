h1#managePassword {
    margin-left: -20px;
}

input#password_input, input#password_confirm_input {
    height: 35px; 
    margin-bottom: 0;
}

button#manageFormButton {
    background: #42425C;
    color: white;
    border: 0;
    padding: 15px;
    margin-top: 10px;
    min-height: 50px;
}

label {
    display: block;
}

form#changePasswordForm, form#changeEmailForm {
    padding: 10px 40px 10px 0px;
    max-width: 500px;
    /* margin: auto; */
    padding: 0;
}

.ReactPasswordStrength-strength-desc {
    width: 100px;
    font-size: 14px;
    font-family: open sans;
    right: 5px!important;
    width: 20%!important;
}

.ReactPasswordStrength-input {
    width: 100%;
}

#managePasswordAlert.alert.alert-success {
    color: #468847;
    background-color: #dff0d8;
    border-color: #d6e9c6;
    height: 50px;
    line-height: 20px;
    padding-left: 2.5%;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 0;
    max-width: 500px;
}

#managePasswordAlert.alert-danger {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 14px;
}

.ReactPasswordStrength {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

input.form-control {
    font-size: 11px;
    color: #444;
    letter-spacing: .75px;
}