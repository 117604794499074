input[type=text]#loginUsername, input[type=password]#loginPassword,
input[type=text]#loginPassword {
    display: inline-block;
    padding: 5px 15px;
    width: 100%;
    border-radius: 20px;
    border: 1px solid #aaaaaa;
    font-size: 16px;
}

#rightLogin input[type=text], #rightLogin input[type=password] {
    font-size: 16px;
}

#forgotPassowrdRightLogin input.ReactPasswordStrength-input {
    border: 1px solid lightgrey;
    border-radius: 5px;
    width: 100%;
    height: 50px;
    /* margin-bottom: -50px; */
}

#loginForm .MuiFormControl-root {
    border: none!important;
    border-bottom: 1px solid lightgrey!important;
    background: none;
    margin-top: 0!important;
}

#loginButtonArea button#loginRegister {
    width: 45%;
    max-width: 200px;
    float: right;
    background: rgb(255, 255, 255);
    border: 1px solid #00808059;
    color: teal;
    height: 50px;
}

#loginButtonArea button#loginLogin {
    width: 45%;
    max-width: 200px;
    float: left;
    background: #29a79f;
    border: 1px solid #00808059;
    color: #fff;
    height: 50px;
}

button#purchasSuccessButton {
    max-width: 200px;
    /* float: left; */
    background: #29a79f;
    /* border: 1px solid #00808059; */
    color: #fff;
    height: 50px;
    text-align: center;
    margin: auto;
    display: block;
    margin-top: 5%;
}

/* #loginButtonArea .makeStyles-primary-4, */
#loginButtonArea button#loginRegister {
    box-shadow: 0 2px 2px 0 rgb(39 170 176 / 14%), 0 3px 1px -2px rgb(39 176 157 / 15%), 0 1px 5px 0 rgb(39 170 176 / 15%);
    /* background-color: #29a79f; */
}

#loginButtonArea button#loginRegister:hover, 
#loginButtonArea button#loginRegister:focus {
    box-shadow: 0 14px 26px -12px rgb(39 176 176 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(39 176 132 / 20%);
    /* background-color: #29a79f; */
}

/* button[name=provider] {
    border-radius: 25px;
    border: 0;
    width: 75%;
    margin-top: 50px;
    padding: 5px;
}
button[name=provider] > img {
    width: 20px;
} */

#loginPassword {
    display: inline-block; 
    width: 90%!important;
}

.password {
    display: inline-block; 
    width: 90%!important;
}

#loginLogo {
    margin: 0px;
    font-family: Ubuntu;
    font-weight: 700;
    color: #29A79F;
    text-align: center;
    margin-bottom: 30px;
    position:inherit!important;
}

#loginLogoLeft {
    text-align: center;
    color: #fff;
    font-size: 5rem;
    margin: auto;
    /* display: block; */
    position: absolute;
    width: 100%;
    top: 50px;
    font-family: Ubuntu;
    font-weight: 700;
}

#loginPage .radio label, #loginPage .checkbox label {
    min-height: 20px;
    font-weight: normal;
    cursor: pointer;
    color: #555;
    display: inline;
}

#loginPage {
    /* height: 100%; */
    width: 100%;
    /* position: fixed; */
    top: 0;
    left: 0;
    margin-left:0px;
    margin-right:0px;
    z-index: 99;
    display: flex;
    /* flex-grow: 1;
    flex-direction: column;
    justify-content: center; */
    font-family: raleway;
}

#loginPage h4 {
    text-align: center; 
    margin-bottom: 40px; 
    color: #555;
    font-family: raleway;
    font-weight: bold;
}

#loginPage > div {
    align-items: stretch;
}

#rightLogin {
    padding: 10%;
    background: #fefefe;
    height: 100vh;
}

#leftLogin {
    height: auto;
    /* background: linear-gradient(0deg, rgba(10, 80, 67, 0.25), rgb(29, 84, 86)), url(https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/opengrants_login_rocket.png); */
    background-size: cover;
    background-position: 50% 100%;
}

#leftcaptjurLogin {
    height: 100%;
    background: linear-gradient(0deg, rgba(10, 80, 67, 0.25), rgb(72, 162, 218)), url(https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/opengrants_login_rocket.png);
    background-size: cover;
    background-position: 50% 100%;
}

#leftsv-ventureLogin {
    height: 100%;
    background: linear-gradient(0deg, rgba(10, 80, 67, 0.25), rgb(21, 155, 178)), url(https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/opengrants_login_rocket.png);
    background-size: cover;
    background-position: 50% 100%;
}

#leftnexusLogin {
    height: 100%;
    background: linear-gradient(0deg, rgba(10, 80, 67, 0.25), rgb(143, 105, 70)), url(https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/opengrants_login_rocket.png);
    background-size: cover;
    background-position: 50% 100%;
}

#leftstartup-garageLogin {
    height: 100%;
    background: linear-gradient(0deg, rgba(10, 80, 67, 0.25), rgb(212, 91, 91)), url(https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/opengrants_login_rocket.png);
    background-size: cover;
    background-position: 50% 100%;
}

/* #captjurButtons {
    background: #127fc4;
    border: #127fc4;
}
#sv-ventureButtons {
    background: #127fc4;
    border: #127fc4;
}
#startup-garageButtons {
    background: rgb(156, 101, 101);
    border: rgb(156, 101, 101);
} */

#loginButtons {
    clear: both;
}

button#login {
    float: left;
}

#partnerLoginLogo {
    text-align: center;
}

#loginSubmitButton, 
#captjurLoginSubmitButton,
#sv-ventureLoginSubmitButton, 
#startup-garageLoginSubmitButton,
#nexusLoginSubmitButton {
    background: #29A79F;
    border-radius: 2px;
    color: white;
    padding: 5px 15%;
    border: 1px solid #29A79F;
    font-family: Raleway;
    height: 40px;
}

#captjurLoginSubmitButton {
    background: #127fc4!important;
    border: #127fc4!important;
}

#sv-ventureLoginSubmitButton {
    background: rgb(21, 155, 178)!important;
    border: rgb(21, 155, 178)!important;
}

#startup-garageLoginSubmitButton {
    background: rgb(156, 101, 101)!important;
    border: rgb(156, 101, 101)!important;
}

#nexusLoginSubmitButton {
    background: #145277!important;
    border: #145277!important;
}

a#loginRegisterButton, 
a#captjurLoginRegisterButton, 
a#sv-ventureLoginRegisterButton,
a#startup-garageLoginRegisterButton,
a#nexusLoginRegisterButton {
    float: right; 
    color: #29A79F; 
    background: white; 
    border: 1px solid #29A79F; 
    border-radius: 2px;
    padding: 5px 15%;
    font-family: Raleway;
    height: 40px;
    line-height: 30px;
}

a#captjurLoginRegisterButton {
    color: #127fc4!important; 
    border: 1px solid #127fc4!important; 
}

a#sv-ventureLoginRegisterButton {
    color: rgb(21, 155, 178)!important; 
    border: 1px solid rgb(21, 155, 178)!important; 
}

a#startup-garageLoginRegisterButton {
    color: rgb(156, 101, 101)!important; 
    border: 1px solid rgb(156, 101, 101)!important; 
}

a#nexusLoginRegisterButton {
    color: #145277!important; 
    border: 1px solid #145277!important; 
}

a#forgotPassword {
    float: right;
    cursor: pointer;
}

#loginHelperButtons {
    margin-top: 10px;
}

h1#navFooter {
    position: absolute;
    font-family: Ubuntu;
    font-size: 24px !important;
    bottom: 25px;
    text-align: center;
    width: 100%;
    color: #fff;
}

hr#loginPageHR {
    border: 0;
    border-top: 1px solid #e0e0e0;
    width: 15%;
    margin: auto;
    margin-bottom: 30px;
}

img#showPassword_login, img#hidePassword_login {
    width: 90%;
    vertical-align: middle;
}

#showPasswordDiv {
    display: inline-block; 
    width: 8%; 
    text-align: right; 
    vertical-align: top; 
    margin-top: 8%;
    margin-left: 2%;
}

#loginMoreInfo {
    text-align: center; 
    font-size: 12px;
}

@media only screen and (min-width: 1250px) {
    form#loginForm{
        padding: 10%;
        padding-top: 30px;
    }
    #rightLogin {
        padding: 0%!important;
    }
}

@media only screen and (max-width: 1250px) and (min-width: 1000px) {
    form#loginForm{
        padding: 10%;
        padding-top: 100px;
    }
}

@media only screen and (max-width: 1000px) {
    #rightLogin {
        width: 100%!important;
    }
}

@media only screen and (max-width: 768px) {
    #leftLogin, 
    #leftPartnerLogin, 
    #leftcaptjurLogin,
    #leftsv-ventureLogin,
    #leftnexusLogin {
        display: none;
    }
}

/* WHITELABEL */
.bannerImg {
    background: transparent !important;
    width: 50%;
    margin-top: 30vh;
}