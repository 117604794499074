#dashboardMiddle {
    height: 325px;
}

#dashboardMiddleSecondary {
    height: 350px;
    width: 100%;
}

p#prospectSVExpertDesc {
    max-width: 850px;
    font-size: 18px;
    margin-bottom: 2.5%;
}

#prospectSVDashboard #dashboardMiddleSecondary {
    height: 325px;
    width: 95%;
    margin-left: 2.5%
}

h2#prospectSVDashboardH2 {
    text-align: center;
    font-weight: 600;
    color: #273f63;
    margin-bottom: -15px;
}

#prospectSVChart {
    margin-bottom: 0px;
}

#prospectSVDashboard .col-lg-6 {
    padding: 0;
}

#dashboardTabs {
    padding: 15px;
    margin-top: -20px!important;
    width: 95%;
    left: 2.5%;
    border-radius: 3px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(41, 126, 167,.4);
    color: #FFF;
    margin: 0;
    padding: 0;
    position: relative;
    font-size: 3em;
    height: 75px;
    padding-top: 14px;
    padding-left: 15px;
    padding-bottom: 15px;
}

#dashboardTabsSecondary {
    padding: 15px;
    /* margin-top: -20px!important; */
    border-radius: 3px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(41, 126, 167,.4);
    color: #FFF;
    margin: 0;
    padding: 0;
    position: relative;
    font-size: 3em;
    height: 75px;
    width: 100%;
    padding-top: 14px;
    padding-left: 15px;
    padding-bottom: 15px;
}

#tabsTitleHeader {
    line-height: 30px!important;
    font-weight: 600!important;
    font-family: open sans;
}

h1#dashHeaderSecondary {
    margin: 0px;
    line-height: 50px;
    font-weight: 600;
    font-family: open sans;
}

/* #dashboardTabs {
    padding: 15px;
    margin-top: -20px!important;
    border-radius: 3px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(41, 126, 167,.4);
    color: #FFF;
    margin: 0 15px;
    padding: 0;
    position: relative;
    font-size: 3em;
    min-height: 75px;
    padding-top: 14px;
    padding-left: 15px;
    padding-bottom: 15px;
} */

#dashboardPanels .dashboardPanel {
    /* box-shadow: 0 2px 5px 0px #e3e5ec; */
    box-shadow: 0 0 0 1px rgba(35,38,59,.05), 0 1px 3px 0 rgba(35,38,59,.15);
    padding: 25px;
    height: 40vh;
    background: #fff;
}

#dashboardTopPanels .dashboardPanel {
    box-shadow: 0 0 0 1px rgba(35,38,59,.05), 0 1px 3px 0 rgba(35,38,59,.15);
    padding: 10px;
    height: 100px;
    min-width: 200px;
}

#dashboardTopPanels .dashboardPanel img {
    height: 60px;
    float: left;
    margin-top: 10px;
    margin-right: -5%;
    margin-left: 10%;
}

#portalDashboard {
    position: absolute;
    background: #fafafa;
    /* min-height: 100vh; */
    height: auto;
    margin-top: 0;
    width: calc(100vw - 300px);
    left: 300px;
    top: 0;
    bottom: 0;
    padding: 25px;
}

#prospectSVResources {
    margin-top: 0;
    margin-left: 2.5%;
    margin-right: 2.5%;
    width: 95%;
}

/* #portalDashboard .MuiSvgIcon-root {
    fill: #fff!important;
    height: 2em!important;
    width: 2em!important;
}

#portalDashboard .MuiSvgIcon-root {
    fill: #fff!important;
    height: 2em!important;
    width: 2em!important;
} */

h1#dashHeader {
    font-size: 24px;
    font-weight: 400;
    font-family: open sans;
    color: #3a4570;
    margin-top: 0;
}

h1#dashHeaderSmall {
    font-size: 18px;
    font-weight: 400;
    font-family: open sans;
    color: #fff;
    margin-top: 10px;
    margin-left: -10px!important;
    text-align: center;
    line-height:30px;
}

.dashboardTopPanelBottom {
    height: 35px;
    border-radius: 0px 0px 2px 2px;
    border: 1px solid;
    border-top: none;
    background: #fff;
    color: #3a4570;
    min-width: 200px;
}

.dashboardTopPanelBottom p {
    color: #7a7f9a;
    font-family: open sans;
    font-size: 13px;
    line-height: 35px;
    padding-left: 10px;
}

.makeStyles-cardCategory-4 {
    color: #555!important;
    margin: 0;
    font-size: 16px;
    margin-top: 0;
    padding-top: 10px;
    margin-bottom: 0;
}

.makeStyles-cardCategoryWhite-5 {
    color: rgba(255, 255, 255, 1)!important;
    margin: 0;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
}

.makeStyles-cardTitle-6 {
    color: #3C4858;
    font-size: 18px;
    line-height: 36px;
    margin-top: 0px;
    min-height: auto;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 300;
    margin-bottom: 3px;
    text-decoration: none;
}

.makeStyles-tableHeadCell-58, .makeStyles-tableHeadCell-58.makeStyles-tableCell-59,
.makeStyles-tableHeadCell-75, .makeStyles-tableHeadCell-75.makeStyles-tableCell-76 {
    font-size: 2em!important;
    color: darkslateblue;
}

.dashboardView p, .dashboardView div>span {
    color: black;
    margin: 0;
    font-size: 16px!important;
    margin-top: 0;
    /* padding-top: 10px; */
    margin-bottom: 0;
}

.MuiTableCell-body {
    font-size: 1.25em!important;
}

.makeStyles-tableCell-76 {
    font-size: 1.5em!important;
}

h6#hitCategoryDash, .categoryTag {
    background: #316a9229;
    border-radius: 2px;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 10px;
    color: #555;
    min-width: 100px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
}

.makeStyles-cardTitle-39 {
    float: left;
    padding: 10px 10px 10px 0px;
    line-height: 24px;
    font-size: 14px;
}

.makeStyles-stats-3 {
    color: #333!important;
}

h1#dashboardHeader {
    font-family: ubuntu;
    margin-top: -10px;
    margin-bottom: 25px;
    color: #23283ad1;
    font-weight: 500;
}

#upcomingAppsTable {
    max-width: 225px!important;
}

#upcomingAppsTableDiv td {
    font-size: 1.25rem!important;
    width: 75%;
}

#upcomingAppsTableDiv th {
    font-size: 2em!important;
    color: darkslateblue;
}

.makeStyles-tableCell-76 {
    padding-right: 25px!important;
    max-width: 250px;
}

h4#dashExpertsOnStaff,
h4#dashFundingOpportunities,
h4#dashSubmissions {
    font-size: 1.75rem!important;
}

.dashboardNavLink {
    width: 100%;
    height: 100%;
}

.dashboardNavLink:hover, .dashboardFooterLink:hover {
    text-decoration: underline;
    text-decoration-color: #7986bba6;
    cursor: pointer;
}

#dashLinkArrow {
    float: right;
    font-size: 20px;
    margin-right: -5px;
    color: #777;
}

#matchesDataTip, #favoritesDataTip {
    width: 100%!important;
}

@media only screen and (max-width: 1000px) {
    #portalDashboard {
        position: absolute;
        background: #fafafa;
        /* min-height: 100vh; */
        height: auto;
        margin-top: 0;
        width: 100%;
        left: 0;
        top: 175px;
        bottom: 0;
        padding: 25px;
    }

    #prospectSVResources {
        margin-top: -50px;
    }
}

/* WHITELABEL */
.CardIconOriginal {
    background:#29a
}
.CardIconClassOriginal {
    background: #29a
}
/* EPRI */
.CardIconClass-epri {
    color: #003399 !important;
    background: none !important;
    box-shadow: none !important;
    padding: 0px !important;
    margin-top: 0px !important;
}
.CardTopColorClass {
    height: 10px;
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}