#mainSliderArea {
    width: 90%;
}

.awssld__bullets button:hover {
    transform: none;
    cursor: unset;
}

li#HIWproductListItem {
    display: block;
    margin-left: -20px;
    margin-bottom: 45px;
    margin-top: 25px!important;
}

.awssld {
    display: block;
    position: absolute;
    width: 100%;
    max-width: 100%;
    bottom: 0;
    top: 0;
}

.awssld__content {
    background-color: #ffffff;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.awssld__bullets {
    position: absolute;
    bottom: 25px;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.awssld__controls__arrow-left:before, .awssld__controls__arrow-left:after, .awssld__controls__arrow-right:before, .awssld__controls__arrow-right:after {
    content: ' ';
    position: absolute;
    right: calc( 50% - ( 0.7071 * (var(--organic-arrow-height) + var(--organic-arrow-thickness)) ) / 2);
    height: 100%;
    border-radius: var(--organic-arrow-border-radius);
    width: var(--organic-arrow-thickness);
    background-color: #cecece;
    transition: transform 0.15s ease-out, background-color 0.15s ease-out;
}

.awssld__controls button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--control-button-width);
    height: 100px;
    position: absolute;
    z-index: 10;
    top: calc(50% - (0.5 * var(--control-button-height)));
    border: none;
    background-color: #00000069;
    color: #fff;
    cursor: pointer;
}

.awssld__wrapper {
    display: block;
    overflow: hidden;
    position: absolute;
    height: 100%;
    left: -5%;
    width: calc(100% - 300px)!important;
}

img#howItWorks1IMG {
    width: 100%;
}

#howItWorksLeft1, 
#howItWorksLeft3, 
#howItWorksLeft4 {
    padding-left: 20%;
    padding-right: 10%;
}

#howItWorksLeft2 {
    padding-right: 10%;
    margin-top: 10vh;
    margin-left: -10%;
}

p#howItWorksChecklist {
    margin-top: -35px;
    margin-left: 35px;
    margin-right: 0;
    margin-bottom: 15px;
    font-family: ubuntu;
    font-size: 1.35vw;
}

#howItWorksLeft2 i#productCheckmark.material-icons {
    font-size: 36px;
}

#howItWorksRight1, 
#howItWorksRight2, 
#howItWorksRight3, 
#howItWorksRight4 {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    bottom: 0;
    background-size: cover;
    background-position: center;
}

#howItWorksRight1 {
    background-image: url(https://s3-us-west-1.amazonaws.com/opengrants.io/opengrants_images/opengrants_free_money.png);
}

#howItWorksRight2 {
    background-position: bottom!important;
    background-image: url(https://s3-us-west-1.amazonaws.com/opengrants.io/opengrants_images/opengrants_mission_impossible.jpg);
}

#howItWorksRight3 {
    background-image: url(https://s3-us-west-1.amazonaws.com/opengrants.io/opengrants_images/opengrants_funding_parachute.png);
}

#howItWorksRight4 {
    background-image: url(https://s3-us-west-1.amazonaws.com/opengrants.io/opengrants_images/opengrants_funding_platter.png);
}

h1#mainLandingLogoHIW {
    position: fixed;
    left: 20px;
    color: #29a79f;
    font-size: 32px;
    z-index: 999;
    font-family: ubuntu;
    cursor: pointer;
}

h1#howItWorksMidHeader {
    font-family: ubuntu;
    color: #333;
    font-weight: 600;
    text-align: left!important;
    font-size: 2.75vw;
    margin-bottom: 10%;
}

h2#howItWorksH2, 
h2#howItWorksH231, 
#h2howItWorksH232,
#h2howItWorksH241,
#h2howItWorksH242 {
    font-family: ubuntu;
    color: #444;
    font-weight: 400;
    font-size: 26px;
    text-align: left;
    margin-bottom: 7%;
}

h2#howItWorksH231 {
    font-size: 24px!important;
}

h2#howItWorksH232 {
    color: #29a79f;
    font-weight: 600!important;
    margin-top: 15%;
    font-family: ubuntu;
}

h2#howItWorksH241 {
    font-size: 24px;
}

h2#howItWorksH242 {
    font-size: 36px;
    font-weight: 600!important;
    font-family: ubuntu;
    margin-top: 5%;
    display: block;
}

h2#howItWorksChecklistH2 {
    font-family: ubuntu;
    color: #444;
    font-weight: 400;
    font-size: 32px;
    text-align: left;
    margin-bottom: 7%;
    margin-top: 10%;
}

.awssld__bullets .awssld__bullets--active {
    transform: scale(1.5);
    background: #e0e0e0;
}
.awssld__bullets button {
    padding: 0;
    display: block;
    width: 16px;
    height: 16px;
    margin: 5px;
    border-radius: 50%;
    background: #e0e0e0;
    text-indent: -9999px;
    overflow: hidden;
    cursor: pointer;
    border: none;
    transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45), background-color 0.175s ease-out;
}

input#howItWorksLetsGo {
    /* background: #53b4ac!important; */
    height: 50px;
    line-height: 0px;
    border-radius: 2px;
    border: none;
    color: #fff;
    font-family: ubuntu;
    font-weight: 300;
    font-size: 20px;
    font-size: 20px;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 5%;
}

@media only screen and (max-width: 992px)
{
    #howItWorksRight1, 
    #howItWorksRight2, 
    #howItWorksRight3, 
    #howItWorksRight4 {
        width: 100%;
        height: 50vh;
        top: 50%;
        margin-bottom: -110px;
        position: relative;
    }
}

@media only screen and (max-width: 768px)
{
    #howItWorksLeft1,
    #howItWorksLeft3, 
    #howItWorksLeft4 {
        padding-left: 10%;
    }

    #howItWorksLeft2 {
        margin-top: 20vh;
        margin-bottom: 10vh;
        padding-left: 5%;
        padding-right: 5%;
    }

    #howItWorksLeft4 {
        margin-bottom: 10vh;
    }

    h2#howItWorksH2, h2#howItWorksH231, #h2howItWorksH232, #h2howItWorksH241, #h2howItWorksH242 {
        font-weight: 400;
        font-size: 20px;
    }

    h1#howItWorksMidHeader {
        font-size: 5vh;
    }

    #howItWorksPanel2 .row {
        width: 100vw!important;
    }

    p#howItWorksChecklist {
        font-size: 1.5vh;
    }

    .awssld__wrapper {
        display: block!important;
        overflow: hidden!important;
        position: absolute!important;
        top: 175px!important;
        height: 100%!important;
        left: -5%!important;
        width: 100%!important;
    }
}

@media only screen and (max-width: 500px)
{
    #howItWorksLeft1,
    #howItWorksLeft4 {
        margin-top: 20vh;
        margin-bottom: 10vh;
    }

    #howItWorksLeft2 {
        margin-top: 0vh;
        padding-left: 5%;
        padding-right: 5%;
    }

    #howItWorksLeft3 {
        margin-top: 25vh;
        margin-bottom: 10vh;
    }

    #howItWorksLeft4 {
        margin-top: 15vh;
    }

    #howItWorksLeft2 h1#howItWorksMidHeader {
        font-size: 1.5em;
    }

    #howItWorksLeft3 h1#howItWorksMidHeader {
        font-size: 2em;
    }

    #howItWorksLeft4 h1#howItWorksMidHeader {
        font-size: 2em;
    }

    h2#howItWorksH2, h2#howItWorksH231, #h2howItWorksH232, #h2howItWorksH241, #h2howItWorksH242 {
        font-weight: 400;
        font-size: 1.5em!important;
    }

    #howItWorksLeft2 h2 {
        font-size: 1.5em;
    }

    p#howItWorksChecklist {
        font-size: 1.5vh;
    }
}