#mobileRecButtons .col-xs-6 {
    padding: 0;
}

#recommendedColumn .col-md-6 {
    padding: 0;
}

.recommendedListing:hover {
    cursor: pointer;
}

#datesRow {
    height: auto;
}

i#addRecommendedtoFavorites.material-icons {
    color: #538b98;
    font-size: 32px;
    padding-right: 0px;
    padding-top: 5px;
    margin: 0;
    cursor: pointer;
    margin-right: 2%;
    width: 1em;
}


i#discardRecommendedfromFavorites.material-icons {
    color: #888!important;
    font-size: 32px!important;
    width: 1em;
    padding-right: 0;
    padding-top: 5px;
    margin: 0;
    margin-right: 2%;
    cursor: pointer;
}

#recommendedTitle h4 {
    line-height: 1em; 
    height: 3em; 
    overflow: hidden; 
    color: #29A79F; 
    width: 75%; 
    font-family: ubuntu; 
    font-weight: 600; 
    font-size: 16px; 
    margin-bottom: 15px; 
    margin-top: 15px; 
    padding-top: 0px;
}

#cat_Tags {
    height: 65px; 
    text-align: center;
}

#cat_tags_button {
    border: 0px; 
    height: 0; 
    background: none; 
    color: #2A88F4; 
    margin-bottom: 10px;
}

hr#recommendedBodyHR {
    display: block!important;
    margin-top: 0;
}

/* #organizationColumn {
    text-align: center;
} */

h4#organizationLabel {
    font-family: ubuntu; 
    color: #666;
}

hr#organizationLabelHR {
    width: 10%; 
    margin-top: 10px; 
    margin-bottom: 10px;
}

p#organizationName, #dueDateColumn p {
    font-size:1em; 
    height: 3em; 
    overflow:hidden; 
    font-weight:400; 
    margin-top: 0; 
    color: #999
}

#dueDateColumn {
    text-align: center;
}

#dueDateColumn h4 {
    font-family: ubuntu; 
    color: #666;
}

#dueDateColumn hr {
    width: 10%; 
    margin-top: 10px; 
    margin-bottom: 10px;
}

#listingTags {
    height: 5em;
    overflow: hidden;
    text-align: left;
    margin-bottom: 3%;
    margin-top: -2%;
    float: left;
}

#listingTags.row {
    max-width: 100% !important;
    padding-left: 5%;
}

#listingTags h6 {
    background: #E0DFE0;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 30px;
    display: inline-block;
    margin: 2px;
    font-size: 10px;
    color: #3e454a;
    height: 3em;
    overflow: hidden;
}

#recommendedTitle h4:hover {
    text-decoration: underline;
    cursor: pointer;
}
.recommendedListing {
    box-shadow: 0 1px 5px -1px rgba(47,54,57,.07), 0 0 5px 0 rgba(47,54,57,.06), 0 1px 10px 0 rgba(47,54,57,.05);
    font-size: 14px;
    display: inline-block;
    background: white;
    padding: 15px;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 100%;
}
#recommendedBody {
    padding: 5%;
    padding-bottom: 1%;
}

@media only screen and (max-width: 999px) {

    .recommendedListing {
        width: 100%;
        margin-right: 0;
        text-align: left !important;
        padding:0;
    }

    .recommendedListing p {
        height: 1.1em!important;
    }

    #recommendedColumn .col-md-6 {
        text-align: left !important;
    }

    #recommendedColumn .col-md-6 hr {
        display: inline-block !important;
    }

    .material-icons.deleteListing {
        display: none;
    }

    .material-icons.saveListing {
        display: none;
    }

    #discardButton {
        width: 90%;
        margin-top: 20px;
        background: none;
        color: #555;
        border: 2px solid #92c1c5;
        border-radius: 7px;
        border: 2px solid #8aa0b361;
        color: #538b98;
        font-family: ubuntu;
        height: 60px;
        margin: 0;
        background-image: linear-gradient(white, #f3f3f3);
        box-shadow: 0 1px 5px -1px rgba(47,54,57,.07), 0 0 5px 0 rgba(47,54,57,.06), 0 1px 10px 0 rgba(47,54,57,.05);
    }

    #saveButton {
        border: 0px;
        margin-top: 20px;
        border-radius: 4px;
        background: #538b98;
        color: white;
        margin: 10px 0px;
        width: 90%;
        border-radius: 7px;
        font-family: ubuntu;
        height: 60px;
        margin: 0;
        background-image: linear-gradient(#528b98, #367477);
        box-shadow: 0 1px 5px -1px rgba(47,54,57,.07), 0 0 5px 0 rgba(47,54,57,.06), 0 1px 10px 0 rgba(47,54,57,.05);
    }

    #recommendedTitle h4 {
        padding-top: 5%!important;
        color: #538b98;
        margin:0;
        font-size: 20px!important;
        height: auto!important;
        padding-bottom: 5%;
    }

    #listingTags {
        padding-left: 7.5%;
        height: auto;
    }

    #listingTags.row {
        max-width: 100% !important;
        height: auto;
    }

    #listingTags h4 {
        display: inherit !important;
    }

    #mobileRecButtons {
        display: inline-flex;
        margin: auto;
        width: 100%;
        padding: 2%;
        text-align: center;
        margin-top: 7%;
        margin-bottom: 5%;
    }

    #cat_tags {
        height: auto!important;
    }
    
    #recommendedBody h4 {
         padding-top: 10%;
    }
}