.plan-table {
    display: flex;
}

.plan-table > div {
    display: flex;
    width: 100%;
    margin: 30px;
}

.plan-table > div > div {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    height: 100%;
    text-align: center;
    padding: 30px;
    position: relative;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
}

.plan-table h3:nth-child(1) {
    margin-bottom: 0;
}

.plan-table h3:nth-child(2) {
    margin-top: 0;
    margin-bottom: 20px;
}

.plan-table hr {
    border-top-color: black;   
}

.plan-table div > hr {
    margin-right: 25%;
    margin-left: 25%;
}

.plan-table .padding-bottom {
    padding-bottom: 50px;
}

.plan-table a {
    position: absolute;
    bottom: 20px;
    left: 20%;
    right: 20%;
    margin: 0;
}

.color-black {
    color: black;
}

.color-grey {
    color: #aaa;
}

.color-og {
    color: #29A79F
}

.color-white {
    color: white;
}

.button {
    background: #29A79F;
    padding: 10px 20px;
    color: white;
    border-radius: 5px;
}

@media only screen and (max-width: 768px) {
    .plan-table {
        display: block;
    }
    
    .plan-table > div {
        display: block;
        width: auto;
    }
    
    .plan-table > div > div {
        display: block;
        border: 1px solid #ddd;
    }
}

.margin-all {
    margin: 5% 15%;
}

.ubuntu h1, 
.ubuntu h2, 
.ubuntu h3, 
.ubuntu a, 
.ubuntu p {
    font-family: 'Ubuntu';
}

.ubuntu p {
    font-weight: 100;
}