#grantWriter {
    font-family: 'Ubuntu';
    color: #585858;
}

#grantWriter h1 {
    font-size: 30px;
    margin-top: -50px;
    margin-bottom: 40px;
    letter-spacing: 8px;
}

hr.airTableHR {
    width: 5%;
    height: 3px;
    background: #999;
    margin-top: -15px;
    margin-bottom: 20px;
}

#grantWriter h2 {
    font-size: 20px;
    margin-bottom: 20px;
}

#grantWriter iframe {
    background: transparent; 
    border: 1px solid #ccc;
    height: 100vh;
}

.flex {
    display: flex;
}

.flex > div {
    flex-grow: 1;
    margin: 10px;
}

.font-ubuntu h1, .font-ubuntu h2, .font-ubuntu h3, .font-ubuntu p, .font-ubuntu button {
    font-family: "Ubuntu";
    color: #444;
}

.font-ubuntu p, .font-ubuntu a {
    font-family: "Ubuntu";
    font-size: 18px;
}