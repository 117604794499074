#billingSettingsDiv .pop {
    box-shadow: none;
    margin-bottom: 0!important;
    padding-top: 0!important;
}

#billingSettingsDiv #stripeCheckout .pop {
    box-shadow: 0 0 10px -6px;
    padding-top: 15px!important;
}

#billingSettingsDiv #stripeCheckout .col-md-12 {
    margin-top: -40px;
}

#billingSettingsDiv #stripeCheckout .background-white.pop.gap-bottom {
    margin-top: 0px;
}

#billingSettingsDiv .background-white.pop.gap-bottom {
    margin-top: -20px;
}

#billingSettingsDiv h3 {     
    display: inline-block;
    font-weight: 600;
    font-size: 20px;
    color: #494f5c;
}

#billingSettingsDiv p {
    font-size: 14px;
}

#billingSettingsDiv button {
    float: right;
    min-width: 150px;
}

.payment-form .error {
    color: red;
    display: block;
}

.payment-form fieldset {
    margin-top: 40px; 
    max-width: 500px;
}

.billingAddress input {
    font-size: 12px!important;
    width: 100%;
    margin-top: 5px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #c0c0c0;
    padding-left: 5px;
}

.payment-form p {
    font-size: 16px;
    /* font-weight: 500; */
    color: #555;
}

.payment-form h1 {
    font-size: 30px;
    margin-bottom: 10px;
    color: teal;
    font-family: ubuntu;
}

.payment-form h2 {
    font-size: 21px; 
    margin-bottom: 10px;
    margin-top: 0;
}

.payment-form h3 {
    font-size: 21px;
    margin-top: 0;
}

.payment-form #card_erros {
    color: red;
}

.payment-form .billingAddress {
    padding-bottom: 5px;
    padding-top: 20px;
}

.payment-form .billingAddress input[type=text] {
    height: 50px;
    width: 100%;
    border: 1px solid #bbb;
    border-radius: 5px;
    padding: 10px;
}

.payment-form .billingAddress div {
    margin-bottom: 10px;
}

/* .payment-form button {
    margin: 0px; 
    margin-top: 0px; 
    background: #29A79F!important;
    border: 0px;
    height: 50px;
    width: 150px;
    border-radius: 4px;
    color: white;
} */

.payment-form .additionalBilling {
    margin-top: 40px; 
    text-align: center;
}

.payment-form .additionalBilling a {
    color: #29A79F;
}

#billingNumbers {
    margin: 0;
    height: 50px;
    line-height: 50px;
    padding: 15px 5px;
    border: 1px solid #d3d3d3!important;
    border-radius: 5px;
    background-color: #eee;
}

.flex-2-1 {
    display: flex;
}

.flex-2-1 :nth-child(1) {
    flex-grow: 2;
}

.flex-2-1 :nth-child(2) {
    flex-grow: 1;
}

.flex-1-1 {
    display: flex;
}

.flex-1-1 :nth-child(1) {
    flex-grow: 1;
}

.flex-1-1 :nth-child(2) {
    flex-grow: 1;
}

#portalProfile #stripePaywallCheckout {
    width: 100%!important;
}

label {
    color: black!important;
}