footer {
    background: #1D4354; 
    width: 100%;
    margin: 0;
}

footer h3, footer h4, footer a {
    color: #fff;
    font-family: open sans;
    margin-top: 0;
}

footer a:hover {
    color: #2AA79F;
}

footer .row {
    padding-left: 10%; 
    padding-right: 10%; 
    padding-top: 3%;
}

#footer-3.col-md-3 {
    width: 22%;
    margin-top: 75px;
}

#footer-4.col-md-3 {
    width: 34%;
    max-width: 500px;
    margin-top: 75px;
}

#footerRow.row {
    padding: 0!important;
}

footer .col-md-2 {
    margin-top: 50px;
}

footer .col-md-6 {
    margin-top: 50px; 
    max-width: 400px;
}

footer.col-md-6 hr {
    width: 15%;
}

footer h3 {
    font-size: 18px; 
    font-weight: normal;
}

footer h4 {
    color:#8e8e8e;
    font-size:14px;
    font-weight: normal;
}

footer hr {
    width: 25%; 
    border: 1px solid #8e8e8e;
}

hr#footerHR {
    width: 95%;
}

#newsletterConfirmation p {
    color: #fff; 
    font-size: 14px;
}

#newsletterError p {
    color: #fff; 
    font-size: 14px;
}

#newsletter_email {
    width: 65% !important;
    height: 40px;
    font-size: 14px;
    padding: 10px 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #D9E6EC;
}

#newsletter_subscribe_button {
    height: 40px;
    width: 35% !important;
    padding: 10px 30px;
    border: 2px solid #2AA79F;
    background: #2AA79F;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 15px;
    color: white;
    font-size: 12px;
    vertical-align: top;
}

input#newsletterEmail {
    width: 60%;
    font-size: 14px;
    padding: 10px 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px; 
    border-top-right-radius: 0; 
    border-bottom-right-radius: 0;
    border: 1px solid #D9E6EC;
}

input#newsletter_subscribe_button {
    padding: 10px 6%;
    border: 2px solid #2AA79F;
    background: #2AA79F;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    color: white;
    font-size: 12px;
    vertical-align: top;
}

h3#totalUsers, h3#totalGrants {
    color: #2AA79F; 
    font-size: 23px; 
    margin-right: 5px;
}

#copyright {
    margin-bottom: 0px; 
    text-align: left; 
    margin-top: 10px; 
    width: 95%; 
    margin:auto;
}

#copyright p {
    margin-bottom: 0px; 
    padding-left: 25px; 
    padding-right: 25px; 
    padding-bottom: 20px; 
    color: #fff; 
    line-height: 2em;
}

a#privacy {
    margin-right: 40px;
    font-weight:normal; 
    padding-left: 25px; 
    padding-right: 25px; 
}

h3#fundingCount, h3#grantCount {
    font-size:13px;
}

#totalFunding, #totalGrants {
    color: #2AA79F; 
    font-size: 23px; 
    margin-right: 5px;
}

a#OpenGrants {
    color:#2AA79F;
}

a#EgeriaCorp {
    color:#2AA79F;
}

@media only screen and (max-width: 999px) {
    #footer-3.col-md-3 {
        width: 100%;
        margin-top: 75px;
    }
    #footer-4.col-md-3 {
        width: 100%;
        margin-top: 75px;
    }
}